import React from 'react';
import { Link } from 'react-router-dom';
import LoadingOverlay from 'react-loading-overlay';
import 'bootstrap/dist/css/bootstrap.min.css';
import {Row, Col, Container, Button, Table} from 'react-bootstrap';
import {ILandholding, ILandholdingAddress} from '../../Models/Landholdings/Landholding';
import LandholdingList from '../LandPortal/LandholdingList';
import Landholding from '../LandPortal/Landholding';
import { IUserProps } from '../../Models/Auth/AuthModels';
import { connect } from 'react-redux';
import {
  GetLandholdings,
  newLandholdingReducerObj,
  stopFetching,
  DeleteLandholding,
  getUserProfile
} from '../../Store/ActionCreators/index';
import { ConfirmModal } from '../../Helpers/ConfirmModal';
import { IUser } from '../../Models/Users/User';

interface ILandPortalProps extends IUserProps {
  isAuthenticated: boolean;
  authButtonMethod: any;
  user: any;
  getTokens: any;
  getAccessToken: any;
  storeLandHoldings: Array<ILandholding>;
  GetLandholdings: Function;
  newLandholdingReducerObj: Function;
  _id: string,
  name: string;
  userId: string;
  address: ILandholdingAddress;
  cadastralInformation: string;
  currentUseDescription: string;
  projectStyles: object;
  availableSizePercentage: number;
  soilTypes: object;
  furtherInformationOrComments: string;
  mapGraphItems?: Array<any>;
  createdBy: string;
  createdAt: Date | undefined;
  updatedBy: string;
  updatedAt: Date | undefined;
  isFetching: boolean;
  stopFetching: Function;
  DeleteLandholding: Function;
  GetUserProfile: Function;
}

interface ILandPortalState {
  isOpen: boolean; //KS: Can define any specific state params
  showDeleteModal: boolean;
  deleteId: string;
}

class Landportal extends React.Component<ILandPortalProps, ILandPortalState> {
  loaderStyle = {
    overlay: (base: any) => ({
      ...base,
      background: 'rgb(211,211,211, 0.7)',
      color: 'black',
      zIndex: 1
    })
  };
  
  constructor(props: ILandPortalProps, state: ILandPortalState) {
    super(props);

    state = {
      isOpen: false,
      showDeleteModal: false,
      deleteId: ''
    };

  }  

  closeModal() {
    this.setState({ ...this.state, showDeleteModal: false, deleteId: '' });
  }

  async componentDidMount() {
    await this.getUserProfile();
    await this.getAllLandholdingForUser();
    //this.setState({ isOpen: true });
    await this.props.stopFetching();
  }

  handleDelete(id: string) {
    this.setState({ ...this.state, showDeleteModal: true, deleteId: id });
  }

  deleteGraphItem = async () => {
    const accessToken = await this.props.getAccessToken();
    await this.props.DeleteLandholding(accessToken, this.state.deleteId);
    await this.getAllLandholdingForUser();
    this.setState({ ...this.state, showDeleteModal: false, });
  }

  getUserProfile = async () => {
    const accessToken = await this.props.getAccessToken();
    if (accessToken) {
      await this.props.GetUserProfile(accessToken);
      this.setState({ ...this.state, showDeleteModal: false, });
    }
  }

  getAllLandholdingForUser = async() : Promise<any> => {
    const { GetLandholdings } = this.props;
    const accessToken = await this.props.getAccessToken();
    if (accessToken)
      await GetLandholdings(accessToken);
  }

  renderLandholdingItems() {

    let fragment = null;

    if (!this.props.storeLandHoldings && this.state.isOpen && !this.props.isFetching) {
      fragment = <>
        {this.props.stopFetching}
        <Table striped bordered hover>
          <tbody>
            <tr>
              <td>No Property records found.<br/><br/>Click on "Add Property" to register your property details to receive information about suitable projects.<br></br>Your property list will appear here once you have registered your property details.</td>
            </tr>                        
          </tbody>
        </Table>
      </>;
    } else {
      if(this.props?.storeLandHoldings?.length) {
          if(this.props.storeLandHoldings.length > 0) {
            fragment = <>
              <LandholdingList>
                {this.props.storeLandHoldings.length > 0 ? (this.props.storeLandHoldings.map((landholding, idx) => (
                  <Landholding
                    _id={landholding._id}
                    key={String(Math.random())}
                    getAccessToken={this.props.getAccessToken}
                    getAllLandholdingForUser={this.getAllLandholdingForUser}
                    name={landholding.name}
                    userId={landholding.userId}
                    address={landholding.address}
                    postalAddress={landholding.postalAddress}
                    cadastralInformation={landholding.cadastralInformation}
                    currentUseDescription={landholding.currentUseDescription}
                    projectStyles={landholding.projectStyles}
                    availableSizePercentage={landholding.availableSizePercentage}
                    soilTypes={landholding.soilTypes}
                    furtherInformationOrComments={landholding.furtherInformationOrComments}
                    mapGraphItems={landholding.mapGraphItems}
                    createdBy={landholding.createdBy}
                    createdAt={landholding.createdAt}
                    updatedBy={landholding.updatedBy}
                    updatedAt={landholding.updatedAt}
                    handleDelete={this.handleDelete.bind(this)}
                  />
                ))
                ) : (
                  <Table striped bordered hover>
                    <tbody>
                      <tr>
                      <td>No Property records found.<br/><br/>Click on "Add Property" to register your property details to receive information about suitable projects.<br></br>Your property list will appear here once you have registered your property details.</td>
                      </tr>                        
                    </tbody>
                  </Table>
                )}
                </LandholdingList>
              </>;
          }
          else {
            fragment = <>          
              <Table striped bordered hover>
                <tbody>
                  <tr>
                  <td>No Property records found.<br/><br/>Click on "Add Property" to register your property details to receive information about suitable projects.<br></br>Your property list will appear here once you have registered your property details.</td>
                  </tr>                        
                </tbody>
              </Table>
            </>;
          }
        }
      else 
        {
          fragment = <>
            <Table striped bordered hover>
              <tbody>
                <tr>
                <td>No Property records found.<br/><br/>Click on "Add Property" to register your property details to receive information about suitable projects.<br></br>Your property list will appear here once you have registered your property details.</td>
                </tr>                        
              </tbody>
            </Table>
          </>;
        }
      }

    return fragment;
  }

  addToReducer() {
    this.props.newLandholdingReducerObj();
  }

  render() {
    return (
      <>
        <ConfirmModal showModal={this.state?.showDeleteModal}
                heading="Delete the property"
                text="Are you sure you want to delete this property?"
                handleConfirm={this.deleteGraphItem.bind(this)}
                handleClose={this.closeModal.bind(this)}
                confirmButtonText="Delete"
        >
        </ConfirmModal>


        <Container fluid>
          <Row style={{marginBottom: "10px"}}>
            <Col></Col>
          </Row>
          <Row>
            {/* {this.props.isAuthenticated && (
                <Col xs={2}>
                  <ButtonGroup vertical>
                    <Button>Landholdings</Button>
                    <Button>Restoration Landholdings</Button>
                  </ButtonGroup>                
                </Col>
              )} */}
            <Col>
              <Container fluid>
                <Row>
                    <Col xs={12} style={{marginBottom: "10px"}}>
                      <Link to={`/landportal/addproperty`}>
                      {this.props.isFetching === false ? (<Button className="float-right"  onClick={() => this.addToReducer()} variant="success">Add Property &gt;&gt;</Button>) : (<Button className="float-right" variant="success" disabled>Add Property &gt;&gt;</Button>)}
                      </Link>
                    </Col>
                </Row>
                <Row>
                  <Col>
                    <LoadingOverlay className="row" active={this.props.isFetching} spinner text='Please wait...' styles={this.loaderStyle}>
                      {this.renderLandholdingItems()}
                    </LoadingOverlay>
                  </Col>
                </Row>                
              </Container>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

const mapStateToProps = (state: { 
    landHoldingsReducer: { 
      storeLandHoldings: any; 
      currentLandHolding: any; 
      isFetching: boolean; 
      keepDirtyOnReinitialize: boolean,
      userProfile: IUser}; }) => ({
  storeLandHoldings: state.landHoldingsReducer.storeLandHoldings,
  isFetching: state.landHoldingsReducer.isFetching,
  userProfile: state.landHoldingsReducer.userProfile
});

export default connect(mapStateToProps, {
  GetLandholdings,
  newLandholdingReducerObj,
  stopFetching,
  DeleteLandholding,
  GetUserProfile: getUserProfile
})(Landportal);