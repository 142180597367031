import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import * as signalR from '@microsoft/signalr'

import { ISignalRNegotiationInfo } from '../../Models/SignalR/ISignalRNegotiationInfo';
import { postSignalRNegotiationInfo } from '../Api/SinglaRApi';
import { AppThunk } from '../RootStore';


export interface ISignalRState {
    url: string;
    accessToken: string;
    connection: any;
    error: any;
}

const initialState: ISignalRState = {
    url: '',
    accessToken: '',
    connection: {} as signalR.HubConnection,
    error: {}
};

const signalRSlice = createSlice({
    name: "SignalR",
    initialState,
    reducers: {        
        getSignalRNegotiationInfoSuccess(state: ISignalRState, action: PayloadAction<ISignalRNegotiationInfo>) {
            state.accessToken = action.payload.accessToken;
            state.url = action.payload.url;
            state.error = {};
        },
        getSignalRNegotiationInfoFailed(state: ISignalRState, action: PayloadAction<any>) {
            state.error = action.payload;
        },

        getSignalRConnectionSuccess(state: ISignalRState, action: PayloadAction<signalR.HubConnection>) {
            state.connection = action.payload
        },
        getSignalRConnectionFailed(state: ISignalRState, action: PayloadAction<signalR.HubConnection>) {
            state.error = action.payload
        }
    }
});

export const SignalRReducer = signalRSlice.reducer;

export const {
    getSignalRNegotiationInfoSuccess,
    getSignalRNegotiationInfoFailed,
    getSignalRConnectionSuccess,
    getSignalRConnectionFailed
} = signalRSlice.actions;

export const getSignalRNegotiationInfo = (accessToken: string) : AppThunk => async dispatch => {
    try {
        const signalRNegotiationInfo : ISignalRNegotiationInfo = await postSignalRNegotiationInfo(accessToken);
        dispatch(getSignalRNegotiationInfoSuccess(signalRNegotiationInfo));
      }
      catch(err) {
        dispatch(getSignalRNegotiationInfoFailed(err));
      }
};

// export const getSignalRConnection = (signalRAccessToken: string, signalRUrl: string) : AppThunk => async dispatch => {
//     try {
//         GetSignalRConnection.createConnection(signalRAccessToken, signalRUrl);
//         const connection = GetSignalRConnection.getConnection();
//         dispatch(getSignalRConnectionSuccess(connection));
//       }
//       catch(err) {
//         dispatch(getSignalRConnectionFailed(err));
//       }
// };
