import React from 'react';
import { Button } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import checkmark from '../../Assets/checkmark-circle.svg';
import './AddLandholding.css';

const ThankYou : React.FC<any> = (props) => {
  const userProfile = useSelector((state: any) => state.userProfile);
  const currentLandHolding = useSelector((state: any) => state.landHoldingsReducer.currentLandHolding);
  const history = useHistory();

  return (
    <React.Fragment>
      <div className="center-title"><h4>Form Progress</h4></div>
      <div className="progress-bar-titles">
        <div className="step-one-location-title">Step 1</div>
        <div className="step-three-location-title">Step 2</div>
        <div className="step-five-location-title">Step 3</div>
        <div className="step-seven-location-title">Completed</div>
      </div>  
      <div className="progress-bar">
        <div className="step-one-location">
          <img
          className="imgs"
          src={checkmark}
          alt="step"
          height="32"
          width="32"
          />
        </div>
        <div className="step-two-location"></div>
        <div className="step-three-location">
          <img
          className="imgs"
          src={checkmark}
          alt="step"
          height="32"
          width="32"
          />
        </div>
        <div className="step-four-location"></div>
        <div className="step-five-location">
          <img
          className="imgs"
          src={checkmark}
          alt="step"
          height="32"
          width="32"
          />
        </div>
        <div className="step-six-location"></div>
        <div className="step-seven-location">
          <img
          className="imgs"
          src={checkmark}
          alt="checkmark"
          height="32"
          width="32"
          />
        </div>
      </div>
      <div className="center-text">
        <p>Thank you for completing your property listing. </p>
        <Button onClick={() => history.push(`/landportal/editproperty/${currentLandHolding._id}`)}>View/Edit property.</Button>
        <p></p>
        <p>Thanks for providing your property information. You will receive an email with your details.</p>
        <Button onClick={() => history.push("/landportal/addproperty")}>Add another property</Button>
        { userProfile?.metaData ? <></> :
          <>
            <p></p>
            <p>Please make sure you complete your profile.</p>
            <Button onClick={() => history.push("/userprofile")}>Complete my Profile</Button>
          </>
        }
      </div>
    </React.Fragment>
  )
}

export default ThankYou;