import { Action, combineReducers } from 'redux';
import { ILandholding } from '../Models/Landholdings/Landholding';
import { landHoldingsReducer } from './Reducers/landHoldings';
import { LandCareChatReducer } from './Reducers/LandCareChatSlice';
import { LocalStoreReducers } from './Reducers/LocalStoreSlice';
import { configureStore, getDefaultMiddleware, ThunkAction } from '@reduxjs/toolkit';
import { SignalRReducer } from './Reducers/SignalRSlice';

export interface ILandPortalState {
  landHoldings: ILandholding
}


const rootStore = configureStore({
  reducer: combineReducers({
    landHoldingsReducer: landHoldingsReducer,
    landCareChat: LandCareChatReducer,
    localStore: LocalStoreReducers,
    SignalRInfo: SignalRReducer
  }),
  middleware: getDefaultMiddleware(),
  devTools: true
});

export default rootStore;

export type RootState = ReturnType<typeof rootStore.getState>;
export type AppDispatch = typeof rootStore.dispatch;
export type AppThunk = ThunkAction<void, RootState, unknown, Action<string>>;

// Need to revisit
// export const RootStore = configureStore({
//   reducer: reducers,
//   middleware: getDefaultMiddleware(),
//   devTools: true
// });

// export default RootStore;

// export type RootState = ReturnType<typeof RootStore.getState>;
// export type AppDispatch = typeof RootStore.dispatch;
// export type AppThunk = ThunkAction<void, RootState, unknown, Action<string>>;