import React from 'react';
import {Table} from 'react-bootstrap';

import './LandholdingList.scss';

interface ILandholdingListState {
}

interface Props {
  children: React.ReactNode;
}

export default class LandholdingList extends React.Component<Props, ILandholdingListState> {
  constructor(props: Props, state: ILandholdingListState) {
    super(props);
  }

  render() {
    if(!this.props.children) {
      return (
        <div>
          <h2>No Properties found.</h2>
        </div>
      );
    }
    return (
      <Table striped bordered hover>
        <thead>
          <tr>
              <th>Properties</th>
          </tr>
          </thead>
        <tbody>
          { this.props.children }
        </tbody>
      </Table>      
    );    
  }
}
