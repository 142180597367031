import React from 'react';
import { loadModules } from 'esri-loader';
import { IBaseMapPoint, IBaseMapPolygon, IBaseMapPolyline } from "../../Models/BaseMap/BaseMapModels";
import lodash from 'lodash';

import { Button, ListGroup, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSyncAlt, faSearch, faTrash } from '@fortawesome/free-solid-svg-icons';
import { ILandholding } from '../../Models/Landholdings/Landholding';

import {
  UpdateMapLandholding,
  CreateMapLandholding
} from '../../Store/ActionCreators/index';
import { connect } from 'react-redux';
import './BaseMap.css';

interface IBaseMapProps {
  portalName?: string;
  listGeometryItems?: Array<any>; 
  currentUser: any;
  getAccessToken: Function;
  thisItem: ILandholding;
  thisObj: any;
  UpdateMapLandholding: Function;
  CreateMapLandholding: Function;
}

interface IBaseMapState {
  status?: string;
  listGeometryItems?: Array<any>; 
  showModal: boolean;
  itemToDelete: any;
  locatePolygon: any
}


const apiKey = process.env.REACT_APP_ARCGIS_API_KEY;

class BaseMap extends React.Component<IBaseMapProps, IBaseMapState> {

  baseMap: any;
  baseMapView: any;
  graphicsLayer: any;
  esriMapObject:any;
  addressLocator:any;

  constructor(props: IBaseMapProps, state: IBaseMapState) {
    super(props);
    
    console.log('Current user ID inside Basemap: ', props.currentUser)

    this.state = {
      status: 'loading',
      listGeometryItems: [],
      showModal: false,
      itemToDelete: {},
      locatePolygon: {}
    }
  }

  componentDidMount() {

    //1 line from initialValues
    console.log('Obj in mapper: ', this.props.thisItem)
    console.log('Reducer obj: ', this.props.thisObj)


    loadModules([
      'esri/views/MapView', 
      'esri/WebMap',
      'esri/widgets/Search',
      'esri/Graphic',
      'esri/layers/GraphicsLayer',
      'esri/widgets/Sketch',
      "esri/config",
      "esri/tasks/Locator",
      "esri/geometry/geometryEngine"
    ])
    .then(([MapView, WebMap, Search, Graphic, GraphicsLayer, Sketch, Config, Locator, GeometryEngine]) => {
      this.esriMapObject = {};
      this.esriMapObject.MapView = MapView;
      this.esriMapObject.WebMap = WebMap;
      this.esriMapObject.Search = Search;
      this.esriMapObject.Graphic = Graphic;
      this.esriMapObject.GraphicsLayer = GraphicsLayer;
      this.esriMapObject.Sketch = Sketch;
      this.esriMapObject.Config = Config;
      this.esriMapObject.Locator = Locator;
      this.esriMapObject.GeometryEngine = GeometryEngine;
      
      //Setup ARC GIS API Key
      this.esriMapObject.Config.apiKey = apiKey;

      //Setup Locator
      this.addressLocator = new this.esriMapObject.Locator ({
        url: "http://geocode.arcgis.com/arcgis/rest/services/World/GeocodeServer"
      });

      this.graphicsLayer = new GraphicsLayer();

      this.renderMap();
      this.renderMapWidgets();
      this.renderMapGraphicItems();
      this.baseMap.add(this.graphicsLayer);
      // Refresh list of geometry items
      this.syncListGeometryItems();
      this.setState({ 
        status: "done",
        listGeometryItems: this.props.listGeometryItems
      });
    })
    .catch(err => {
      // handle any errors
      console.error(err);
    });
  }

  handleMapItemChange = (graphItems: Array<any>, action: string) => {
    console.log("Got add map item event from child: ", graphItems);   
    switch(action.toLocaleLowerCase()) {
      case "add":        
        console.log("Received add item request");
        const currItems = this.state.listGeometryItems ? this.state.listGeometryItems : [];
        currItems.push(graphItems[0]);
        this.updateDB(currItems);
      break;
      case "replace":
        this.updateDB(graphItems);
        break;
      case "delete":
        console.log("Received delete item request");
        const listSans = this.state.listGeometryItems?.forEach(item => {
          return item !== graphItems
        })
        this.updateDB(listSans);
        break;
      // case "clear":
      //   console.log("Received clear all request");
      //   this.setState({ listGeometryItems: [] })
      //   this.graphicsLayer.graphics = []
      //   this.syncListGeometryItems()
      //   this.updateDB([]);
      //   break;
      default:
        console.log("Received no op");
        break;
    }
    console.log("Check state object", this.props.listGeometryItems);
  }

  renderBaseMap = () => {
    if(this.state.status === 'loading') {
      return <div>loading</div>;
    }
    else {
      return <></>;
    }
  }

  renderMap = () => {


    const mapId = "86265e5a4bbb4187a59719cf134e0018";  // ESRI imaging hybrid (see https://www.arcgis.com/home/item.html?id=86265e5a4bbb4187a59719cf134e0018)
		const mapCenter = [133.77, -28.27]; // best value for mapId center at mapZoomLevel
		const mapZoomLevel = 5.5; // best zoom level of centered map

    // then we load a web map from an id
    this.baseMap = new this.esriMapObject.WebMap({
      portalItem: { // autocasts as new PortalItem()
        id: mapId
      }
    });

    // and we show that map in a container w/ id #viewDiv 
    this.baseMapView = new this.esriMapObject.MapView({
      map: this.baseMap,
      container: 'viewDiv',
			zoom: mapZoomLevel,
			center: mapCenter
    });

    // Use other way to zoomToPolygon
    this.baseMapView.on("layerview-create", (event: any) => {
      var info = "<br> <span> layerview-create </span> - " + event.layer.title + " is " + event.layer.loadStatus;
      console.log("Info: ", info);
      this.zoomToFirstPolygonFeature();
    });

  }

  renderMapWidgets = () => {

    let esriMapObject = this.esriMapObject;
    let addressLocator = this.addressLocator;
    let geometryEngine = this.esriMapObject.GeometryEngine;
    let syncMapPolygons = this.syncListGeometryItems;

    // Setup defaults
    this.baseMapView.ui.components = ["zoom", "compass", "home", "attribution"];

    // Search widget
    var search = new esriMapObject.Search({
      view: this.baseMapView
    });
    this.baseMapView.ui.add(search, "top-left");

    // Sketch widget
    let sketch = new esriMapObject.Sketch({
      view: this.baseMapView,
      layer: this.graphicsLayer,      
      creationMode: "single" // user will now need to select button to create a new polygon ; see https://bit.ly/2NYFcfh
    });

    sketch.visibleElements = {
      createTools: {
          point: false,
          circle: false,
          polyline: false,
          rectangle: false
      },
      selectionTools:{   
        "lasso-selection": false,
        "rectangle-selection": false
      },
      undoRedoMenu: false
    };

    // Listen to sketchViewModel's create event.
    sketch.on("create", function(event: any) {

      // check if the create event's state has changed to complete indicating the graphic create operation is completed.
      if (event.state === "complete") {

        if(event.graphic && event.graphic.geometry && event.graphic.geometry.extent) {
          //let polygonCentryPoint = [145.70702, -34.09864];
          let polygonGraphic = event.graphic;
          polygonGraphic.attributes = {};

          const polygonCenterPoint = event.graphic.geometry.extent.center
          polygonGraphic.attributes.centerPoint = [polygonCenterPoint.latitude, polygonCenterPoint.longitude];

          const params = {
            location: polygonCenterPoint
          };
          addressLocator.locationToAddress(params)
          .then(async (response: any) => { // Show the address found          
            //showAddress(address, evt.mapPoint);
            console.log("Found Address:", response.address);
            polygonGraphic.attributes.title = response.address;
            polygonGraphic.attributes.address = response.address;

            //Can use spatial reference of either WGS-84 (wkid: 4326) or Web Mercator Auxiliary Sphere (wkid: 3857)
            //This method only works with WGS-84 (wkid: 4326) and Web Mercator (wkid: 3857) spatial references
            //var area = geometryEngine.geodesicArea(geometryEngine.simplify(polygonGraphic.geometry), "acres");
            // event.graphic.layer.title = `${response.address} - Area: ${area} Hectares`;
            var area = geometryEngine.geodesicArea(geometryEngine.simplify(polygonGraphic.geometry), "hectares");
            console.log("Polygon Area:", area);
            polygonGraphic.attributes.area = area;
            await syncMapPolygons();

          }, (err: any) => { // Show no address found
            //showAddress("No address found.", evt.mapPoint);
            console.log("Address Error:", err);
          });
        }
      }
    });

    this.baseMapView.ui.add(sketch, "top-right");    
  }

  renderMapGraphicItems = () => {

    const listGeometryItemsRender = this.props.listGeometryItems;

    if(listGeometryItemsRender && listGeometryItemsRender.length > 0) {
      listGeometryItemsRender.forEach((item) => {
        switch(item.type) {
          case "Point":
          case "point":
            // corrected below by Kaush: this.renderMapPoints(item.id, item.lat, item.lng);
            //this.renderMapPoints(item.id, item.geometry.x, item.geometry.y);
            this.renderMapPoints(item);
            break;
          case "Polyline":
          case "polyline":
            //this.renderMapLines(item.id, item.paths);
            this.renderMapLines(item);
            break;
          case "Polygon":
          case "polygon" :
            //this.renderMapPolygons(item.id, item.rings);
            this.renderMapPolygons(item);
            break;
          default:
            console.log("Got unknown item", item);
            break;
        }
      });
    }
    else{
      console.log("Got No Map GraphicItems");
    }
  }

  //https://developers.arcgis.com/web-map-specification/objects/spatialReference/
  //renderMapPoints = (pointId: number, x: number, y: number, spatialReference?: any) => {
  renderMapPoints = (pointItem: any, spatialReference?: any) => {

    console.log("Rendering point on map");
    var simpleMarkerSymbol = {
      type: "simple-marker",
      color: [192, 192, 192], // silver
      outline: {
        color: [80, 80, 80], // dark-silver
        width: 1
      }
    };    
    
    var point = {
      type: "point",
      //latitude: lat, //34.0005930608889,
      //longitude: lng // -118.80657463861
      x: pointItem.geometry.x,
      y: pointItem.geometry.y,
      spatialReference: {
        latestWkid: 3857,
        wkid: 102100
      }
    };
    
    if(spatialReference) {
      point.spatialReference = spatialReference;
    }
    
    var pointGraphic = new this.esriMapObject.Graphic({
      symbol: simpleMarkerSymbol,
      geometry: point,
      attributes: {
        gId: pointItem.id
      }
    });

    this.graphicsLayer.add(pointGraphic);
  }

  //https://developers.arcgis.com/web-map-specification/objects/spatialReference/
  //renderMapLines = (polylineId: number, paths: Array<Array<number>>, spatialReference?: any) => {
  renderMapLines = (polylineToAdd: any, spatialReference?: any) => {

    var simpleLineSymbol = {
      type: "simple-line",
      color: [80, 80, 80], // dark-silver
      width: 1.5
    };

    var polyline = {
      type: "polyline",
      paths: polylineToAdd.paths,
      spatialReference: {
        latestWkid: 3857,
        wkid: 102100
      }
    };

    if(spatialReference) {
      polyline.spatialReference = spatialReference;
    }

    var polylineGraphic = new this.esriMapObject.Graphic({
      symbol: simpleLineSymbol,
      geometry: polyline,
      attributes: {
        gId: polylineToAdd.id
      }
    });

    this.graphicsLayer.add(polylineGraphic);
  }

  //https://developers.arcgis.com/web-map-specification/objects/spatialReference/
  //renderMapPolygons = (polygonId: number, rings: Array<Array<number>>, spatialReference?: any) => {
  renderMapPolygons = (polygonToAdd: any, spatialReference?: any) => {
    
    console.log('Rendering polygon on map.')
    var simpleFillSymbol = {
      type: "simple-fill",
      color: [192, 192, 192, 0.6], // silver, opacity 60%
      outline: {
        color: [80, 80, 80], // dark-silver
        width: 1
      }
    };
    
    //rings: polygonToAdd.rings,    
    var polygon = {
      type: "polygon",
      rings: polygonToAdd.geometry.rings,
      spatialReference: {
        latestWkid: 3857,
        wkid: 102100
      }
    };

    if(spatialReference) {
      polygon.spatialReference = spatialReference;
    }
    
    var polygonGraphic = new this.esriMapObject.Graphic({
      symbol: simpleFillSymbol,
      geometry: polygon,
      attributes: {
        gId: polygonToAdd.id,
        centerPoint: polygonToAdd.centerPoint,
        title: polygonToAdd.title,
        area: polygonToAdd.area,
        address: polygonToAdd.address
      }
    });
    
    this.graphicsLayer.add(polygonGraphic);
  }

  clearAllGeometryItems = () => {
    let mapGeometryItems = this.graphicsLayer.graphics;
    console.log("Items:", mapGeometryItems.items);
    this.graphicsLayer.graphics.removeAll();
    this.syncListGeometryItems();
    //this.syncBaseMapWithParentLandholdings("clear");
  }

  syncListGeometryItems = async () => {
    let listGeometryItems : Array<IBaseMapPoint | IBaseMapPolyline | IBaseMapPolygon | any> = [];
    let mapGeometryItems = this.graphicsLayer.graphics;
    for(let idx = 0; idx < mapGeometryItems.items.length; idx++) {
      let gItem = mapGeometryItems.items[idx];
      switch(gItem.geometry.type) {
        case "Point": // Lat, Lng
        case "point" :
          console.log("Got Point as: (Id: " + gItem.uid + ")  ", gItem.geometry);
          //graphic.layer.title
          listGeometryItems.push({type: "Point",  
            id: gItem.uid, 
            centerPoint: gItem.attributes?.centerPoint, 
            address: gItem.attributes?.address,
            title: gItem.attributes?.title, 
            area: gItem.attributes?.area, 
            lat: gItem.geometry.latitude, 
            lng: gItem.geometry.longitude, 
            geometry: gItem.geometry});
          break;
        case "Polyline": // Paths
        case "polyline" :
          console.log("Got Polyline as: (Id: " + gItem.uid + ")  ", gItem.geometry);
          listGeometryItems.push({type: "Polyline",  
            id: gItem.uid, 
            centerPoint: gItem.attributes?.centerPoint, 
            address: gItem.attributes?.address,
            title: gItem.attributes?.title, 
            area: gItem.attributes?.area, 
            paths: gItem.geometry.paths, 
            geometry: gItem.geometry});
          break;
        case "Polygon": // Rings
        case "polygon" :
          console.log("Got Polygon as: (Id: " + gItem.uid + ")  ", gItem.geometry);
          listGeometryItems.push({type: "Polygon",  
            id: gItem.uid, 
            centerPoint: gItem.attributes?.centerPoint, 
            address: gItem.attributes?.address,
            title: gItem.attributes?.title, 
            area: gItem.attributes?.area, 
            geometry: gItem.geometry});
          break;
        default: // ??
          console.log("Got Item as: (Id: " + gItem.uid + ")  ", gItem.geometry);
          listGeometryItems.push({type: "Item",  
          id: gItem.uid, 
          centerPoint: gItem.attributes?.centerPoint, 
          address: gItem.attributes?.address,
          title: gItem.attributes?.title, 
          area: gItem.attributes?.area, 
          geometry: gItem.geometry});
          break;
      }
    }

    let stateToUpdate: any = {};
    stateToUpdate.status = this.state.status;
    stateToUpdate.listGeometryItems = listGeometryItems;

    this.updateDB(stateToUpdate);
  }

  updateDB = async (stateToUpdate: any) => {
    if (stateToUpdate.listGeometryItems && stateToUpdate.listGeometryItems.length !== 0) 
    {
      const accessToken = await this.props.getAccessToken();
      console.log('ID triggering conditional: ', this.props.thisItem._id)
      console.log('Obj with that ID: ', this.props.thisItem)
      console.log('Reducer obj in Map handler: ', this.props.thisObj)

      if (this.props.thisItem._id && this.props.thisItem._id.length > 0) {
        console.log('List of shapes: ', stateToUpdate.listGeometryItems)
        await this.props.UpdateMapLandholding(accessToken, this.props.thisItem._id, this.props.thisObj, stateToUpdate.listGeometryItems)
      } else {
        console.log('Created called for new entry.')
        let thisObj = { ...this.props.thisObj };
        if (!thisObj.name) {
          thisObj.name = stateToUpdate.listGeometryItems[0].title;
        }
        await this.props.CreateMapLandholding(accessToken, thisObj, stateToUpdate.listGeometryItems)
      }
      console.log('UpdateDB called with: ', accessToken, ' access token, ', this.props.currentUser, ' holding id which should be same as ', this.props.thisItem._id, ' with polygon list of: ', stateToUpdate.listGeometryItems, ' polygon list to the object passed into update of: ', this.props.thisItem)
    } else {
      console.log('IF NOT CALLED, SHOULD BE EMPTY: ', stateToUpdate)
    }

    this.setState({
      listGeometryItems: stateToUpdate.listGeometryItems
    });
  }

  renderMapGeometryItems = () => {

    let fragment = <></>;

    const listGeometryItems = this.state.listGeometryItems;
    
    if(listGeometryItems && listGeometryItems.length > 0) {
      fragment = (
      <>
        {listGeometryItems.map((item: any, idx: number) => (
          <ListGroup.Item key={item.id}>
              <span className="float-right">
                {/* <FontAwesomeIcon icon={faSyncAlt} size="1x" data-value={item.id} onClick={() => this.refreshPolygonAttributes(item)} /> &nbsp; */}
                <FontAwesomeIcon icon={faSearch} size="1x" data-value={item.id} onClick={() => this.locatePolygonHandler(item)} /> &nbsp;
                <FontAwesomeIcon icon={faTrash} size="1x" data-value={item.id} onClick={() => this.showModalHandler(item)} />
              </span>
            <span>Location: {item.address} [Area: {(Math.round(item.area * 100) / 100).toFixed(2)} hectares]</span>
          </ListGroup.Item>
        ))}
      </>
      );
    }
    else {      
      fragment = <>
        <ListGroup.Item>No shape items found.</ListGroup.Item>
      </>;
    }

    return fragment;
  }

  deleteGraphItem = (event:any) => {

    console.log('Delete clicked')
    let itemIdT = this.state.itemToDelete;
    console.log('Item clicked: ', itemIdT)

    event.preventDefault();

    let isDeleteSuccess = false;

    const graphicItems = this.graphicsLayer.graphics.items;
    console.log('graphicItems: ', graphicItems)
    for(let idx = 0; idx < graphicItems.length; idx++) {
      let gItem = graphicItems[idx];
      if(gItem.uid === itemIdT.id) {
        this.graphicsLayer.graphics.remove(gItem);
        isDeleteSuccess = true;
        console.log('Item deleted: ', gItem.uid, gItem.id);
        break;
      }
    } 
    
    if(isDeleteSuccess) {
      this.syncListGeometryItems();
    }
    this.setState({showModal: false})
  }

  handleCloseModal = () => {
    this.setState({ showModal: false})
  }
  
  showModalHandler = (item: any) => {
    this.setState({ 
      showModal: true,
      itemToDelete: item
    })
  }

  refreshPolygonAttributes = (polygonItem: any) => {
    
    let addressLocator = this.addressLocator;
    let geometryEngine = this.esriMapObject.GeometryEngine;   

    //polygonItem.geometry
    if(polygonItem && polygonItem.geometry && polygonItem.geometry.extent) {
      //let polygonCentryPoint = [145.70702, -34.09864];
      // if(!polygonItem.attributes) {
      //   polygonItem.attributes = {};
      // }

      const polygonCenterPoint = polygonItem.geometry.extent.center
      polygonItem.centerPoint = [polygonCenterPoint.latitude, polygonCenterPoint.longitude];

      const params = {
        location: polygonCenterPoint
      };
      addressLocator.locationToAddress(params)
      .then(async (response: any) => { // Show the address found          
        //showAddress(address, evt.mapPoint);
        //console.log("Found Address:", response.address);
        polygonItem.title = response.address;
        polygonItem.address = response.address;

        //Can use spatial reference of either WGS-84 (wkid: 4326) or Web Mercator Auxiliary Sphere (wkid: 3857)
        //This method only works with WGS-84 (wkid: 4326) and Web Mercator (wkid: 3857) spatial references
        //var area = geometryEngine.geodesicArea(geometryEngine.simplify(polygonGraphic.geometry), "acres");
        // event.graphic.layer.title = `${response.address} - Area: ${area} Hectares`;
        var area = geometryEngine.geodesicArea(geometryEngine.simplify(polygonItem.geometry), "hectares");
        console.log("Polygon Area:", area);
        polygonItem.area = area;
        
        let currListGeometryItems = this.state.listGeometryItems;
        if(currListGeometryItems) {
          for(let idx=0; idx < currListGeometryItems.length; idx++) {
            if(currListGeometryItems[idx].id === polygonItem.id) {
              currListGeometryItems[idx] = polygonItem;
              this.setState({
                listGeometryItems: currListGeometryItems
              });
              return;
            }
          }          
        }

      }, (err: any) => { // Show no address found
        //showAddress("No address found.", evt.mapPoint);
        console.log("Address Error:", err);
      });
    }
  }

  locatePolygonHandler = (polygonItem: any) => {
    this.zoomToPolygonFeature(polygonItem);
  }

  zoomToPolygonFeature = (polygonItem: any) => {

    // let hasAtLeastOnePolygon = false;
    // let firstPolygon;
    // let polygonToCenterAt: any;

    if(this.graphicsLayer && this.graphicsLayer.graphics && this.graphicsLayer.graphics.items) {
      //Navigate through the graphics, and set the extent of the first polygon
      //this.graphicsLayer.graphics.items[0].geometry.extent
      let mapGeometryItems = this.graphicsLayer.graphics;

      if(mapGeometryItems && mapGeometryItems.length > 0) {

        if(polygonItem && polygonItem.geometry && polygonItem.geometry.extent) {            
            this.baseMapView.goTo(polygonItem.geometry.extent);
        }
        // else {
        //   //Get the latest added item
        //   let itemIdx = mapGeometryItems.length - 1; 
        //   let gItem = mapGeometryItems.items[itemIdx];
        //   if(gItem && gItem.geometry && gItem.geometry.extent) {
        //     this.baseMapView.goTo(gItem.geometry.extent);
        //   }
        // }
      }
    }
  }

  zoomToFirstPolygonFeature = () => {

    let hasAtLeastOnePolygon = false;
    let firstPolygon;
    let polygonToCenterAt: any;

    if(this.graphicsLayer && this.graphicsLayer.graphics && this.graphicsLayer.graphics.items) {
      //Navigate through the graphics, and set the extent of the first polygon
      //this.graphicsLayer.graphics.items[0].geometry.extent
      let mapGeometryItems = this.graphicsLayer.graphics;
      for(let idx = 0; idx < mapGeometryItems.items.length; idx++) {

        let gItem = mapGeometryItems.items[idx];
        let feature = gItem.geometry;
        if(feature.type === 'polygon' || feature.type === 'Polygon')
        {
            if(!hasAtLeastOnePolygon) {
              hasAtLeastOnePolygon = true;
              //polygonToCenterAt = {...feature);
              //polygonToCenterAt = Object.assign({}, feature);
              //polygonToCenterAt = JSON.parse(JSON.stringify(feature));
              //firstPolygon = lodash.cloneDeep(feature);
              polygonToCenterAt = lodash.cloneDeep(feature);
              //polygonToCenterAt.rings = feature.rings;

              //console.log("Shape is a polygon...", firstPolygon);
              this.baseMapView.goTo(feature.extent); //Works!
            }
            // else {
            //   feature.rings[0].forEach( (item: any) => {
            //     polygonToCenterAt.rings[0].push(item);
            //   });
            // }

            // var polygonCenter = feature.extent.center; //Works! Gets Lat/Lon at the point!
            // //https://developers.arcgis.com/javascript/latest/api-reference/esri-views-MapView.html
            // this.baseMapView.center = [polygonCenter.longitude, polygonCenter.latitude];
            // this.baseMapView.zoom = 8;            
        }
      }
      
      // if(hasAtLeastOnePolygon) {
      //   this.baseMapView.goTo(polygonToCenterAt.extent); //ToCheck
      // }
    }
  }

  render() {
    return (
      <>  
        <Modal size="lg" show={this.state.showModal} onHide={this.handleCloseModal}>
          <Modal.Header closeButton>
            <Modal.Title>Delete Item</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Choosing delete will permanently delete this item.
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.handleCloseModal}>
              Close
            </Button>
            <Button variant="primary" onClick={this.deleteGraphItem}>
              Delete permanently
            </Button>
          </Modal.Footer>
        </Modal>       
        <div id="mapDiv">
          <div id="viewDiv" style={{height: '80vh', width: '100%'}}>{this.renderBaseMap}</div>
        </div>
        {/* <div className="inline-block btn-margin">
          <Button onClick={this.syncListGeometryItems}>Save All Graph Items</Button>
        </div>
        <div className="float-right btn-margin">
          <Button onClick={() => this.clearAllGeometryItems()}>Clear All Permanently</Button>
        </div> */}
        <div>
          <ListGroup>
            {this.renderMapGeometryItems()}
          </ListGroup>
        </div>
      </>
    )
  }
}

const mapStateToProps = (state: { landHoldingsReducer: { storeLandHoldings: any; currentLandHolding: any; isFetching: boolean; keepDirtyOnReinitialize: boolean }; }) => ({
  currentLandHolding: state.landHoldingsReducer.currentLandHolding
});

export default connect(mapStateToProps, {
  UpdateMapLandholding,
  CreateMapLandholding
})(BaseMap);