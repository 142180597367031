export enum ActionTypes {
  getLandholdings,
  createLandholding,
  getLandholdingById,
  deleteLandholdings,
  getUserProfile,
  saveUserProfile,
  updateForm,
  getNewReducerObj,
  stopFetching,
  copyAddress,
  setError,
  getCorrelationId,
  setCorrelationId,
  clearCurrentLandholding,
  resetError
}