import React from 'react';

import 'bootstrap/dist/css/bootstrap.min.css';

interface IProjectProps {
  key: string;
  id: string;
  name: string;
}

interface IProjectState {
  isOpen: boolean //KS: Can define any specific state params
}

class Project extends React.Component<IProjectProps, IProjectState> {

  constructor(props: IProjectProps, state: IProjectState) {
    super(props);

    //KS: Set default state params as required
    this.state = {
      isOpen: false
    };
  }

  render() {
    console.log("Got Params as", this.props);
    return (
      <tr key={this.props.id} onClick={() => this.handleTableRowClick(this.props)}>
        <td>Project (Id: {this.props.id}): {this.props.name}</td>
      </tr>
    );
  }

  handleTableRowClick = (project: IProjectProps) => {
    console.log("Got click event for: " + project.name);
  }
}

export default Project;
