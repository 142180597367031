import React from 'react';
import { Link } from 'react-router-dom';
import { Spinner, OverlayTrigger, Tooltip } from "react-bootstrap";
import { ILandholdingAddress } from '../../Models/Landholdings/Landholding';
import {
  editFormData,
  DeleteLandholding
} from '../../Store/ActionCreators/index';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import 'bootstrap/dist/css/bootstrap.min.css';
import './AddLandholding.css';

interface ILandholdingProps {
  _id?: string;
  key: string;
  name: string;
  editFormData: Function;
  getAllLandholdingForUser: Function;
  getAccessToken: any;
  DeleteLandholding: Function;
  userId: string;
  address: ILandholdingAddress;
  postalAddress: ILandholdingAddress;
  cadastralInformation: string;
  currentUseDescription: string;
  projectStyles: object;
  availableSizePercentage: number;
  soilTypes: object;
  furtherInformationOrComments: string;
  mapGraphItems?: Array<any>;
  createdBy: string;
  createdAt: Date | undefined;
  updatedBy: string;
  updatedAt: Date | undefined;
  handleDelete: any;
}

interface ILandholdingState {
}

class Landholding extends React.Component<ILandholdingProps, ILandholdingState> {

  constructor(props: ILandholdingProps, state: ILandholdingState) {
    super(props);
  }

  handleTableRowClick = (project: ILandholdingProps) => {
    this.props.editFormData(project);
  }

  deleteGraphItem = async () => {
    const accessToken = await this.props.getAccessToken();
    await this.props.DeleteLandholding(accessToken, this.props._id);
    await this.props.getAllLandholdingForUser();
  }

  render() {
    return (
      <React.Fragment>
        {this.props._id === undefined || this.props._id === null ? (<div className="fetching-centered">
          <Spinner animation="border" variant="secondary" size="sm">
          </Spinner>
          </div>) : (
          <tr key={this.props._id} onClick={() => this.handleTableRowClick(this.props)}>
          <td>
            <div>
              <Link to={`/landportal/editproperty/${this.props._id}`}>
                Property: {this.props.name} 
              </Link>
              <OverlayTrigger
                placement="left"
                overlay={<Tooltip id="button-tooltip-2">Delete this forever?</Tooltip>}
              >
                <span className="float-right">
                  <FontAwesomeIcon icon={faTrash} size="sm" onClick={() => this.props.handleDelete(this.props._id)} />
                </span>
              </OverlayTrigger>
            </div>
          </td>
        </tr>
        )}
      </React.Fragment>
    );
  }
}

export default connect(null, {
  editFormData,
  DeleteLandholding
})(Landholding);
