import axios, { AxiosRequestConfig } from 'axios';
import { Action } from 'redux';
import { ThunkAction } from "redux-thunk";
import { AddressType, ILandholding, ILandholdingPut } from "../../Models/Landholdings/Landholding";
import { IBaseMapPoint, IBaseMapPolygon, IBaseMapPolyline } from "../../Models/BaseMap/BaseMapModels";
import { ActionTypes } from '../Actions/types';
import { initialState } from '../Reducers/landHoldings';
import { IUser, IUserProfile } from '../../Models/Users/User';
import { MessageModeEnum, showMessage } from '../../Helpers/Validator/validationHelper';

export interface GetLandholding {
  type: ActionTypes.getLandholdings;
  payload: ILandholding;
};

export interface DeleteLandholding {
  type: ActionTypes.deleteLandholdings;
  payload: ILandholding;
};

export interface GetLandholdingById {
  type: ActionTypes.getLandholdingById;
  payload: ILandholding;
};

export interface GetUserProfile {
  type: ActionTypes.getUserProfile;
  payload: IUserProfile;
};

export interface SaveUserProfile {
  type: ActionTypes.saveUserProfile;
  payload: IUserProfile;
};

export interface CreateEntryCall {
  type: ActionTypes.createLandholding;
  payload: any;
};

export interface MapUpdate {
  type: ActionTypes.createLandholding;
  payload: any;
};

export interface NewObj {
  type: ActionTypes.createLandholding;
  payload: ILandholding;
};

export interface StopFetching {
  type: ActionTypes.stopFetching;
};

export interface CopyAddress {
  type: ActionTypes.copyAddress;
  payload: boolean;
};

export interface SetCorrelationId {
  type: ActionTypes.setCorrelationId;
  payload: string;
};

export interface SetError {
  type: ActionTypes.setError,
  payload: string
};

const baseUrl: (string | undefined) = process.env.REACT_APP_LANDPORTAL_HOLDINGS_BASEURI;
const adminUrl: (string | undefined) = process.env.REACT_APP_ADMINURI;
const mainUrl: (string | undefined) = process.env.REACT_APP_API_URI;
const landholdingsUrl = `${baseUrl}holdings`;

export const CreateLandholding = (accessToken: string, landholding: ILandholding) : ThunkAction<void, typeof initialState, null, Action<any>> => async dispatch => {
  const config = {
    headers: { 
      'Authorization': `Bearer ${accessToken}`
    }
  };
  
  // On Creation, the _id needs to be set to undefined or MongoDb ignores create new record
  console.log('CREATE ACCESS TOKEN: ', accessToken);
  console.log('Obj. in create: ', landholding)
  const landholdingObj: ILandholding = {
    _id: undefined,
    correlationId: landholding.correlationId,
    name: landholding.name,
    userId: landholding.userId ? landholding.userId : "userID",
    //addresses : [],
    address: {
      addressType: AddressType.default,
      country: "Australia",
      fullAddress: "Full address: ",
      isDirty: true,
      lat: "",
      lon: "",
      line1: landholding.address.line1,
      line2: landholding.address.line2,
      postCode: landholding.address.postCode,
      state: landholding.address.state,
      streetNumber: "",
      streetType: "",
      streetName: "",
      suburb: ""
    },    
    postalAddress: {
      addressType: AddressType.default,
      country: "Australia",
      fullAddress: "Full address: ",
      isDirty: true,
      lat: "",
      lon: "",
      line1: landholding.address.line1,
      line2: landholding.address.line2,
      postCode: landholding.address.postCode,
      state: landholding.address.state,
      streetNumber: "",
      streetType: "",
      streetName: "",
      suburb: ""
    }, 
    cadastralInformation: landholding.cadastralInformation ? landholding.cadastralInformation : "",
    currentUseDescription: landholding.currentUseDescription ? landholding.currentUseDescription : "",
    projectStyles: {
      isConservationBiodiversity : landholding.projectStyles.isConservationBiodiversity ? landholding.projectStyles.isConservationBiodiversity : false,
      isConversionOfLowProductiveAndDegradedLand: landholding.projectStyles.isConversionOfLowProductiveAndDegradedLand ? landholding.projectStyles.isConversionOfLowProductiveAndDegradedLand : false,
      isRevegetationBlockPlanting: landholding.projectStyles.isRevegetationBlockPlanting ? landholding.projectStyles.isRevegetationBlockPlanting : false,
      isRevegerationLinearBeltPlanting: landholding.projectStyles.isRevegerationLinearBeltPlanting ? landholding.projectStyles.isRevegerationLinearBeltPlanting : false,
      isSaltLandsPlanting: landholding.projectStyles.isSaltLandsPlanting ? landholding.projectStyles.isSaltLandsPlanting : false,
      isPaddockTrees: landholding.projectStyles.isPaddockTrees ? landholding.projectStyles.isPaddockTrees : false,
      isCarbonOffset: landholding.projectStyles.isCarbonOffset ? landholding.projectStyles.isCarbonOffset : false,
      isWaterQualityOffset: landholding.projectStyles.isWaterQualityOffset ? landholding.projectStyles.isWaterQualityOffset : false,
      isRiverRiparianRestoration: landholding.projectStyles.isRiverRiparianRestoration ? landholding.projectStyles.isRiverRiparianRestoration : false,
      isWetlandRestoration: landholding.projectStyles.isWetlandRestoration ? landholding.projectStyles.isWetlandRestoration : false,
      isErosionControl: landholding.projectStyles.isErosionControl ? landholding.projectStyles.isErosionControl : false,
      isOther: landholding.projectStyles.isOther ? landholding.projectStyles.isOther : false,
      isOtherDescription: landholding.projectStyles.isOtherDescription ? landholding.projectStyles.isOtherDescription : ""
    },
    availableSizePercentage: landholding.availableSizePercentage ? landholding.availableSizePercentage : 0,
    soilTypes: {
      isClayAndSilts: landholding.soilTypes.isClayAndSilts ? landholding.soilTypes.isClayAndSilts : false,
      isSand: landholding.soilTypes.isSand ? landholding.soilTypes.isSand : false,
      isGravel: landholding.soilTypes.isGravel ? landholding.soilTypes.isGravel : false,
      isLoam: landholding.soilTypes.isLoam ? landholding.soilTypes.isLoam : false,
      isRocky: landholding.soilTypes.isRocky ? landholding.soilTypes.isRocky : false,
      isOutCroppingRock: landholding.soilTypes.isOutCroppingRock ? landholding.soilTypes.isOutCroppingRock : false,
      isOther: landholding.soilTypes.isOther ? landholding.soilTypes.isOther : false, //isSDOther
      isOtherDescription: landholding.soilTypes.isOtherDescription ? landholding.soilTypes.isOtherDescription : ""
    },
    furtherInformationOrComments: landholding.furtherInformationOrComments ? landholding.furtherInformationOrComments : "",
    mapGraphItems: landholding.mapGraphItems ? landholding.mapGraphItems : [],
    createdBy: landholding.userId ? landholding.userId : "userID",
    updatedBy: landholding.userId ? landholding.userId : "userID"//,
    //createdAt: new Date(),
    //updatedAt: new Date()
  }

  try {
    const response = await axios.post<any> (landholdingsUrl, landholdingObj, config);
    if (response.data.code && response.data.code === 11000) {
      console.log('Duplicate creation stopped by the backend');
    }
    else {
      dispatch<CreateEntryCall>({
        type: ActionTypes.createLandholding,
        payload: response.data.result
      });
    }

  }
  catch (ex: any) {
    console.log('CreateLandholding - ', ex.message)
    dispatch<SetError>({
      type: ActionTypes.setError,
      payload: '[CreateLandholding]: ' + ex.message + ' - ' + JSON.stringify(landholdingObj)
    });
  }
};

export const newLandholdingReducerObj = () : ThunkAction<void, typeof initialState, null, Action<any>> => async dispatch => {

  const newReducerObj: ILandholding = {
    _id: "",
    correlationId: "",
    name: "",
    userId: "",
    //addresses: [],
    address: {
      addressType : AddressType.default,
      country: "",
      isDirty: true,
      fullAddress: "Full address: ",
      lat: "",
      lon: "",
      line1: "",
      line2: "",
      postCode: "",
      state: "",
      streetNumber: "",
      streetType: "",
      streetName: "",
      suburb: ""
    },
    postalAddress: {
      addressType : AddressType.default,
      country: "",
      isDirty: true,
      fullAddress: "Full address: ",
      lat: "",
      lon: "",
      line1: "",
      line2: "",
      postCode: "",
      state: "",
      streetNumber: "",
      streetType: "",
      streetName: "",
      suburb: ""
    },
    cadastralInformation: "",
    currentUseDescription: "",
    projectStyles: {
      isConservationBiodiversity: false,
      isConversionOfLowProductiveAndDegradedLand: false,
      isRevegetationBlockPlanting: false,
      isRevegerationLinearBeltPlanting: false,
      isSaltLandsPlanting: false,
      isPaddockTrees: false,
      isCarbonOffset: false,
      isWaterQualityOffset: false,
      isRiverRiparianRestoration: false,
      isWetlandRestoration: false,
      isErosionControl: false,
      isOther: false,
      isOtherDescription: ""
    },
    availableSizePercentage: 0,
    soilTypes: {
      isClayAndSilts: false,
      isSand: false,
      isGravel: false,
      isLoam: false,
      isRocky: false,
      isOutCroppingRock: false,
      isOther: false,
      isOtherDescription: ""
    },
    furtherInformationOrComments: "",
    mapGraphItems: [],
    createdBy: "userID",
    updatedBy: "userID"//,
    //createdAt: new Date(),//.toLocaleString(),
    //updatedAt: new Date()//.toLocaleString()
  }

  dispatch<NewObj>({
    type: ActionTypes.createLandholding,
    payload: newReducerObj
  });
};

export const UpdateForm = (accessToken: string, landholding: any, event: number, formEntry: any, currentProps: any) : ThunkAction<void, typeof initialState, null, Action<any>> => async dispatch => {    
    console.log('Event in AC: ', event)
    //is defined here, shows no input as undefined as the default value:
    console.log('Props obj in AC: ', currentProps)
    //Current reducer store:
    const myObj: ILandholding | any = landholding
    console.log('Landholding in reducer obj. coming into AC: ', myObj)

    console.log('formEntry: ', formEntry)

    console.log('Landholding obj: ', landholding)

    //const reducerObj = currentProps
    const editedObj: any = {
      _id: landholding._id !== undefined ? landholding._id : landholding._id,
      correlationId: landholding.correlationId,
      userId: landholding.userId ? landholding.userId : landholding._id,
      name: event === 1 ? formEntry : landholding.namefield,
      fullAddress: landholding.line1 + '' + landholding.state + '' + landholding.country + '' + landholding.postCode,
      address: {
        line1: event === 2 ? formEntry : landholding.line1,
        postCode: event === 3 ? formEntry : landholding.postCode,
        state: event === 4 ? formEntry : landholding.state,
      },
      postalAddress: {
        line1: event === 5 ? formEntry : landholding.postalLine1,
        postCode: event === 6 ? formEntry : landholding.postalPostCode,
        state: event === 7 ? formEntry : landholding.postalState,
      },
      cadastralInformation: event === 8 ? formEntry : landholding.cadastralInformation,
      currentUseDescription: event === 9 ? formEntry : landholding.currentUseDescription,
      projectStyles: {
        isConservationBiodiversity: event === 10 ? formEntry : landholding.isConservationBiodiversity,
        isConversionOfLowProductiveAndDegradedLand: event === 11 ? formEntry : landholding.isConversionOfLowProductiveAndDegradedLand,
        isRevegetationBlockPlanting: event === 12 ? formEntry : landholding.isRevegetationBlockPlanting,
        isRevegerationLinearBeltPlanting: event === 13 ? formEntry : landholding.isRevegerationLinearBeltPlanting,
        isSaltLandsPlanting: event === 14 ? formEntry : landholding.isSaltLandsPlanting,
        isPaddockTrees: event === 15 ? formEntry : landholding.isPaddockTrees,
        isCarbonOffset: event === 16 ? formEntry : landholding.isCarbonOffset,
        isWaterQualityOffset: event === 17 ? formEntry : landholding.isWaterQualityOffset,
        isRiverRiparianRestoration: event === 18  ? formEntry : landholding.isRiverRiparianRestoration,
        isWetlandRestoration: event === 19 ? formEntry : landholding.isWetlandRestoration,
        isErosionControl: event === 20 ? formEntry : landholding.isErosionControl,
        isOther: event === 21 ? formEntry : landholding.landholdingStyleOther,
        isOtherDescription: event === 22 ? formEntry : landholding.projectStyleDescription
      },
      availableSizePercentage: event === 23 ? formEntry : landholding.availableSizePercentage,
      soilTypes: {
        isClayAndSilts: event === 24 ? formEntry : landholding.isClayAndSilts,
        isSand: event === 25 ? formEntry : landholding.isSand,
        isGravel: event === 26 ? formEntry : landholding.isGravel,
        isLoam: event === 27 ? formEntry : landholding.isLoam,
        isRocky: event === 28 ? formEntry : landholding.isRocky,
        isOutCroppingRock: event === 29 ? formEntry : landholding.isOutCroppingRock,
        isOther: event === 30 ? formEntry : landholding.soilAndDescriptionOther,
        isOtherDescription: event === 31 ? formEntry : landholding.soilTypeDescription
      },
      furtherInformationOrComments: event === 32 ? formEntry : landholding.furtherInformationOrComments,
      mapGraphItems: landholding.mapGraphItems ? landholding.mapGraphItems : [],
      createdBy: landholding.createdBy ? landholding.createdBy : '',
      updatedBy: landholding.updatedBy ? landholding.updatedBy : ''//,
      //createdAt: new Date(),
      //updatedAt: new Date()
      //streetName: landholding.address.streetName ? landholding.address.streetName : '',
      //suburb: landholding.address.suburb ? landholding.address.suburb : '',
      //country: landholding.address.country ? landholding.address.country : '',
      //lat: landholding.address.lat ? landholding.address.lat : '',
      //lng: landholding.address.lng ? landholding.address.lng : '',
      //cadastralInformation: landholding.cadastralInformation ? landholding.cadastralInformation : '',
    }

    dispatch({
      type: ActionTypes.updateForm,
      payload: editedObj
    })
};

export const getReducerObj = () : ThunkAction<void, typeof initialState, null, Action<any>> => async dispatch => {
  dispatch({
    type: ActionTypes.getNewReducerObj
  });
};

export const UpdateLandholding = (accessToken: string, landholding: any) : ThunkAction<void, typeof initialState, null, Action<any>> => async dispatch => {
  const config = {
    headers: { 
      'Authorization': `Bearer ${accessToken}`
    }
  };

  let landholdingConfig: any = {
    _id: landholding._id,
    name: landholding.name,
    userId: landholding.userId ? landholding.userId : "userID",
    address: {
      country: "Australia",
      fullAddress: "Full address: ",
      lat: "",
      lng: "",
      line1: landholding.address ? landholding.address.line1 : "",
      line2: landholding.address ? landholding.address.line2 : "",
      postCode: landholding.address ? landholding.address.postCode : "",
      state: landholding.address ? landholding.address.state : "",
      streetNumber: "",
      streetType: "",
      streetName: "",
      suburb: ""
    },
    postalAddress: {
      country: "Australia",
      fullAddress: "Full address: ",
      lat: "",
      lng: "",
      line1: landholding.address ? landholding.postalAddress.line1 : "",
      line2: landholding.address ? landholding.postalAddress.line2 : "",
      postCode: landholding.address ? landholding.postalAddress.postCode : "",
      state: landholding.address ? landholding.postalAddress.state : "",
      streetNumber: "",
      streetType: "",
      streetName: "",
      suburb: ""
    },
    cadastralInformation: landholding.cadastralInformation ? landholding.cadastralInformation : "",
    currentUseDescription: landholding.currentUseDescription ? landholding.currentUseDescription : "",
    projectStyles: {
      isConservationBiodiversity: landholding.projectStyles.isConservationBiodiversity ? landholding.projectStyles.isConservationBiodiversity : false,
      isConversionOfLowProductiveAndDegradedLand: landholding.projectStyles.isConversionOfLowProductiveAndDegradedLand ? landholding.projectStyles.isConversionOfLowProductiveAndDegradedLand : false,
      isRevegetationBlockPlanting: landholding.projectStyles.isRevegetationBlockPlanting ? landholding.projectStyles.isRevegetationBlockPlanting : false,
      isRevegerationLinearBeltPlanting: landholding.projectStyles.isRevegerationLinearBeltPlanting ? landholding.projectStyles.isRevegerationLinearBeltPlanting : false,
      isSaltLandsPlanting: landholding.projectStyles.isSaltLandsPlanting ? landholding.projectStyles.isSaltLandsPlanting : false,
      isPaddockTrees: landholding.projectStyles.isPaddockTrees ? landholding.projectStyles.isPaddockTrees : false,
      isCarbonOffset: landholding.projectStyles.isCarbonOffset ? landholding.projectStyles.isCarbonOffset : false,
      isWaterQualityOffset: landholding.projectStyles.isWaterQualityOffset ? landholding.projectStyles.isWaterQualityOffset : false,
      isRiverRiparianRestoration: landholding.projectStyles.isRiverRiparianRestoration ? landholding.projectStyles.isRiverRiparianRestoration : false,
      isWetlandRestoration: landholding.projectStyles.isWetlandRestoration ? landholding.projectStyles.isWetlandRestoration : false,
      isErosionControl: landholding.projectStyles.isErosionControl ? landholding.projectStyles.isErosionControl : false,
      isOther: landholding.projectStyles.isOther ? landholding.projectStyles.isOther : false,
      isOtherDescription: landholding.projectStyles.isOtherDescription ? landholding.projectStyles.isOtherDescription : ""
    },
    availableSizePercentage: landholding.availableSizePercentage ? landholding.availableSizePercentage : 0,
    soilTypes: {
      isClayAndSilts: landholding.soilTypes.isClayAndSilts ? landholding.soilTypes.isClayAndSilts : false,
      isSand: landholding.soilTypes.isSand ? landholding.soilTypes.isSand : false,
      isGravel: landholding.soilTypes.isGravel ? landholding.soilTypes.isGravel : false,
      isLoam: landholding.soilTypes.isLoam ? landholding.soilTypes.isLoam : false,
      isRocky: landholding.soilTypes.isRocky ? landholding.soilTypes.isRocky : false,
      isOutCroppingRock: landholding.soilTypes.isOutCroppingRock ? landholding.soilTypes.isOutCroppingRock : false,
      isOther: landholding.soilTypes.isOther ? landholding.soilTypes.isOther : false,
      isOtherDescription: landholding.soilTypes.isOtherDescription ? landholding.soilTypes.isOtherDescription : ""
    },
    furtherInformationOrComments: landholding.furtherInformationOrComments ? landholding.furtherInformationOrComments : "",
    mapGraphItems: landholding.mapGraphItems, //? landholding.mapGraphItems : [],
    createdBy: landholding.userId ? landholding.userId : "userID",
    updatedBy: landholding.userId ? landholding.userId : "userID"//,
    //createdAt: new Date(),
    //updatedAt: new Date()
  }
  const landholdingsId = `${baseUrl}holdings?id=${landholding._id}`;
  try {
    const response = await axios.put<ILandholding>(landholdingsId, landholdingConfig, config);
    dispatch<CreateEntryCall>({
      type: ActionTypes.createLandholding,
      payload: landholdingConfig
    });
  }
  catch (ex: any) {
    dispatch<SetError>({
      type: ActionTypes.setError,
      payload: '[UpdateLandholding]: ' + ex.message + ' - ' + JSON.stringify(landholdingConfig)
    });
  }
};

export const CreateMapLandholding = (accessToken: string, landholding: any, listGeometryItems : Array<IBaseMapPoint | IBaseMapPolyline | IBaseMapPolygon | any>) : ThunkAction<void, typeof initialState, null, Action<any>> => async dispatch => {
  const config = {
    headers: { 
      'Authorization': `Bearer ${accessToken}`
    }
  };

  console.log('Access token in create map AC: ', accessToken)

  let landholdingCombined: ILandholding = {
    _id: undefined,
    correlationId: landholding.correlationId,
    name: landholding.name,
    userId: landholding.userId ? landholding.userId : "userID",
    //addresses: [],
    address: {
      addressType : AddressType.default,
      isDirty: true,
      country: "Australia",
      fullAddress: "Full address: ",
      lat: "",
      lon: "",
      line1: landholding?.address?.line1,
      line2: landholding?.address?.line2,
      postCode: landholding?.address?.postCode,
      state: landholding?.address?.state,
      streetNumber: "",
      streetType: "",
      streetName: "",
      suburb: ""
    },
    postalAddress: {
      addressType : AddressType.default,
      isDirty: true,
      country: "Australia",
      fullAddress: "Full address: ",
      lat: "",
      lon: "",
      line1: landholding?.address?.line1,
      line2: landholding?.address?.line2,
      postCode: landholding?.address?.postCode,
      state: landholding?.address?.state,
      streetNumber: "",
      streetType: "",
      streetName: "",
      suburb: ""
    },
    cadastralInformation: landholding.cadastralInformation ? landholding.cadastralInformation : "",
    currentUseDescription: landholding.currentUseDescription ? landholding.currentUseDescription : "",
    projectStyles: {
      isConservationBiodiversity : landholding?.projectStyles?.isConservationBiodiversity,
      isConversionOfLowProductiveAndDegradedLand: landholding?.projectStyles?.isConversionOfLowProductiveAndDegradedLand,
      isRevegetationBlockPlanting: landholding?.projectStyles?.isRevegetationBlockPlanting,
      isRevegerationLinearBeltPlanting: landholding?.projectStyles?.isRevegerationLinearBeltPlanting,
      isSaltLandsPlanting: landholding?.projectStyles?.isSaltLandsPlanting,
      isPaddockTrees: landholding?.projectStyles?.isPaddockTrees,
      isCarbonOffset: landholding?.projectStyles?.isCarbonOffset,
      isWaterQualityOffset: landholding?.projectStyles?.isWaterQualityOffset,
      isRiverRiparianRestoration: landholding?.projectStyles?.isRiverRiparianRestoration,
      isWetlandRestoration: landholding?.projectStyles?.isWetlandRestoration,
      isErosionControl: landholding?.projectStyles?.isErosionControl,
      isOther: landholding?.projectStyles?.isOther,
      isOtherDescription: landholding?.projectStyles?.isOtherDescription || ""
    },
    availableSizePercentage: landholding.availableSizePercentage ? landholding.availableSizePercentage : 0,
    soilTypes: {
      isClayAndSilts: landholding?.soilTypes?.isClayAndSilts,
      isSand: landholding?.soilTypes?.isSand,
      isGravel: landholding?.soilTypes?.isGravel,
      isLoam: landholding?.soilTypes?.isLoam,
      isRocky: landholding?.soilTypes?.isRocky,
      isOutCroppingRock: landholding?.soilTypes?.isOutCroppingRock,
      isOther: landholding?.soilTypes?.isOther, //isSDOther
      isOtherDescription: landholding?.soilTypes?.isOtherDescription || ""
    },
    furtherInformationOrComments: landholding.furtherInformationOrComments ? landholding.furtherInformationOrComments : "",
    //didn't have landholding in obj before:
    mapGraphItems: listGeometryItems || [],
    createdBy: landholding.userId || "userID",
    updatedBy: landholding.userId || "userID"//,
    //createdAt: new Date(),
    //updatedAt: new Date()
  }

  try {
    const response = await axios.post<any> (landholdingsUrl, landholdingCombined, config);
    
    if (response.data.code === 11000) {
      console.log('Duplicate creation stopped by the backend');
    }
    else {
      dispatch<CreateEntryCall>({
        type: ActionTypes.createLandholding,
        payload: response.data.result
      });
    }
  }
  catch (ex: any) {
    dispatch<SetError>({
      type: ActionTypes.setError,
      payload: '[CreateMapLandholding]: ' + ex.message + ' - ' + JSON.stringify(landholdingCombined)
    });
  }
};

export const UpdateMapLandholding = (accessToken: string, landholdingId: string, landholding: any, listGeometryItems : Array<IBaseMapPoint | IBaseMapPolyline | IBaseMapPolygon | any>) : ThunkAction<void, typeof initialState, null, Action<any>> => async dispatch => {

  const config = {
    headers: { 
      'Authorization': `Bearer ${accessToken}`
    }
  };

  let landholdingCombined = {
    _id: landholding._id,
    userId: landholding.userId,
    address: {
      line1: landholding.address? landholding.address.line1 : '',
      line2: landholding.address? landholding.address.line2 : '',
      postCode: landholding.address? landholding.address.postCode : '',
      state: landholding.address? landholding.address.state : '',
    },
    postalAddress: {
      line1: landholding.postalAddress? landholding.postalAddress.line1 : '',
      line2: landholding.postalAddress? landholding.postalAddress.line2 : '',
      postCode: landholding.postalAddress? landholding.postalAddress.postCode : '',
      state: landholding.postalAddress? landholding.postalAddress.state : '',
    },
    fullAddress: landholding.line1 + '' + landholding.state + '' + landholding.country + '' + landholding.postCode,
    name: landholding.name,
    cadastralInformation: landholding.cadastralInformation,
    currentUseDescription: landholding.currentUseDescription,
    projectStyles: {
      isConservationBiodiversity : landholding.projectStyles.isConservationBiodiversity,
      isConversionOfLowProductiveAndDegradedLand: landholding.projectStyles.isConversionOfLowProductiveAndDegradedLand,
      isRevegetationBlockPlanting: landholding.projectStyles.isRevegetationBlockPlanting,
      isRevegerationLinearBeltPlanting: landholding.projectStyles.isRevegerationLinearBeltPlanting,
      isSaltLandsPlanting: landholding.projectStyles.isSaltLandsPlanting,
      isPaddockTrees: landholding.projectStyles.isPaddockTrees,
      isCarbonOffset: landholding.projectStyles.isCarbonOffset,
      isWaterQualityOffset: landholding.projectStyles.isWaterQualityOffset,
      isRiverRiparianRestoration: landholding.projectStyles.isRiverRiparianRestoration,
      isWetlandRestoration: landholding.projectStyles.isWetlandRestoration,
      isErosionControl: landholding.projectStyles.isErosionControl,
      isOther: landholding.projectStyles.isOther,
      isOtherDescription: landholding.projectStyles.isOtherDescription
    },
    soilTypes: {
      isClayAndSilts: landholding.soilTypes.isClayAndSilts,
      isSand: landholding.soilTypes.isSand,
      isGravel: landholding.soilTypes.isGravel,
      isLoam: landholding.soilTypes.isLoam,
      isRocky: landholding.soilTypes.isRocky,
      isOutCroppingRock: landholding.soilTypes.isOutCroppingRock,
      isOther: landholding.soilTypes.isOther, //isSDOther
      isOtherDescription: landholding.soilTypes.isOtherDescription
    },
    mapGraphItems: listGeometryItems ? listGeometryItems : [],
    availableSizePercentage: landholding.availableSizePercentage,
    furtherInformationOrComments: landholding.furtherInformationOrComments,
    createdBy: landholding.createdBy,
    //createdAt: new Date(),
    updatedBy: landholding.updatedBy//,
    //updatedAt: new Date()
  }

  const landholdingsId = `${baseUrl}holdings?id=${landholdingId}`;
  try {
    await axios.put<ILandholdingPut>(landholdingsId, landholdingCombined, config);

    dispatch<MapUpdate>({
      type: ActionTypes.createLandholding,
      payload: landholdingCombined
    });
  }
  catch (ex: any) {
    dispatch<SetError>({
      type: ActionTypes.setError,
      payload: '[UpdateMapLandholding]: ' + ex.message + ' - ' + JSON.stringify(landholdingCombined)
    });
  }
};

export const editFormData = (landholding: ILandholding) : ThunkAction<void, typeof initialState, null, Action<any>> => async dispatch => {
  dispatch<CreateEntryCall>({
    type: ActionTypes.createLandholding,
    payload: landholding
  })
};

export const stopFetching = () : ThunkAction<void, typeof initialState, null, Action<any>> => async dispatch => {
  dispatch<StopFetching>({
    type: ActionTypes.stopFetching
  })
};

export const GetLandholdings = (accessToken: string): ThunkAction<void, typeof initialState, null, Action<any>> => async dispatch => {
  const config = {
    headers: { 
      'Authorization': `Bearer ${accessToken}`
    }
  };
  try {
    const response = await axios.get(landholdingsUrl, config)
    
    dispatch<GetLandholding>({
      type: ActionTypes.getLandholdings,
      payload: response.data.landholdings
    });
  }
  catch (ex: any) {
    dispatch<SetError>({
      type: ActionTypes.setError,
      payload: '[GetLandholdings]: ' + ex.message + ' - ' + landholdingsUrl
    });
  }
};

export const DeleteLandholding = (accessToken: string, landholdingId: string) : ThunkAction<void, typeof initialState, null, Action<any>> => async dispatch =>  {
  const config = {
    headers: { 
      'Authorization': `Bearer ${accessToken}`
    }
  };
  const landholdingsId = `${baseUrl}holdings?id=${landholdingId}`;
  try {
    const response = await axios.delete(landholdingsId, config);
    console.log('Response after AC deleted: ', response.data.landholdings);
    dispatch<DeleteLandholding>({
      type: ActionTypes.deleteLandholdings,
      payload: response.data.landholdings
    });
  }
  catch (ex: any) {
    dispatch<SetError>({
      type: ActionTypes.setError,
      payload: '[DeleteLandholding]: ' + ex.message + ' - ' + landholdingId
    });
  }
};

export const getLandholdingById = (accessToken: string, landholdingId: string) : ThunkAction<void, typeof initialState, null, Action<any>> => async dispatch => {
  const config = {
    headers: { 
      'Authorization': `Bearer ${accessToken}`
    }
  };
  const landholdingsUrl = `${baseUrl}holdings?id=${landholdingId}`;
  try {
    const response = await axios.get(landholdingsUrl, config);
    dispatch<GetLandholdingById>({
      type: ActionTypes.getLandholdingById,
      payload: response.data.landholdings
    });
  }
  catch (ex: any) {
    dispatch<SetError>({
      type: ActionTypes.setError,
      payload: '[getLandholdingById]: ' + ex.message + ' - ' + landholdingsUrl
    });
  }
};

export const getUserProfile = (accessToken: string) : ThunkAction<void, typeof initialState, null, Action<any>> => async dispatch => {

  const config = {
    headers: { 
      'Authorization': `Bearer ${accessToken}`
    }
  };
  const userProfileUrl = `${baseUrl}userprofile?listusers=true`;
  try {
    const response = await axios.get(userProfileUrl, config);
    let userProfile = {} as IUserProfile;

    if (response.data && response.data.user && response.data.user.length > 0){
      userProfile = { ...response.data.user[0], orgUsers: response.data.orgUsers };
    }

    dispatch<GetUserProfile>({
      type: ActionTypes.getUserProfile,
      payload: userProfile
    });
  }
  catch (ex: any) {
    // dispatch<SetError>({
    //   type: ActionTypes.setError,
    //   payload: '[getUserProfile]: ' + ex.message + ' - ' + userProfileUrl
    // });
    console.log('[getUserProfile]: ' + ex.message + ' - ' + userProfileUrl);
  }
};

export const saveUserProfile = (accessToken: string, userProfile: IUserProfile) : ThunkAction<void, typeof initialState, null, Action<any>> => async dispatch => {

  const config = {
    headers: { 
      'Authorization': `Bearer ${accessToken}`
    }
  };
  const userProfileUrl = `${baseUrl}userprofile`;

  try {
    const response = await axios.put<any>(userProfileUrl, userProfile, config);

    if(response.data && response.data.result){
      userProfile = response.data.result;
    }

    // dispatch<SaveUserProfile>({
    //   type: ActionTypes.saveUserProfile,
    //   payload: userProfile
    // });

    showMessage("User Profile saved successfully", MessageModeEnum.SUCCESS);
  }
  catch (ex: any) {
    const payload = '[saveUserProfile]: ' + ex.message + ' - ' + JSON.stringify(userProfile);
    dispatch<SetError>({
      type: ActionTypes.setError,
      payload
    });
    showMessage(payload, MessageModeEnum.ERROR);
  }
};

export const deleteUserOrgContact = (accessToken: string, id: string) : ThunkAction<void, typeof initialState, null, Action<any>> => async dispatch => {
  const config : AxiosRequestConfig = {
    headers: {
      'Authorization': `Bearer ${accessToken}`
    }
  };

  const url = `${adminUrl}configure/users?id=${id}`;
  try {
    const response = await axios.delete(url, config);
  }
  catch (ex: any) {
    dispatch<SetError>({
      type: ActionTypes.setError,
      payload: '[deleteUserOrgContact]: ' + ex.message + ' - ' + JSON.stringify(url)
    });
  };
};

export const GetCorrelationId = (accessToken: string) : ThunkAction<void, typeof initialState, null, Action<any>> => async dispatch => {
  const config : AxiosRequestConfig = {
    headers: {
      'Authorization': `Bearer ${accessToken}`
    }
  };

  const url = `${mainUrl}core/guid`;
  try {
    const response = await axios.get(url, config);

    dispatch<SetCorrelationId>({
      type: ActionTypes.setCorrelationId,
      payload: response.data.guid
    });
  }
  catch (ex: any) {
    dispatch<SetError>({
      type: ActionTypes.setError,
      payload: '[deleteUserOrgContact]: ' + ex.message + ' - ' + JSON.stringify(url)
    });
  };
};

export const clearCurrentLandholding = () : ThunkAction<void, typeof initialState, null, Action<any>> => async dispatch => {
  dispatch({
    type: ActionTypes.clearCurrentLandholding
  });
};
