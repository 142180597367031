import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import App from './Components/App/App';
import * as serviceWorker from './Services/serviceWorker';
import { AuthProvider, getApiAccessToken, msalInstance } from './Containers/Auth/MsalBrowserProvider';
import { getIsUserAuthenticated, getUserInfo } from './Services/serviceHelper';
import rootStore from './Store/RootStore';

import './index.css';

const isAuthenticated = getIsUserAuthenticated();

const login = () => {
  //window.location.href = process.env.REACT_APP_GERX_LOGOUT_REDIRECT_URL + '/signin';
  msalInstance.loginRedirect();
};

const logout = () => {
  //msalInstance.logoutRedirect();
  localStorage.clear();
  window.location.href = process.env.REACT_APP_GERX_LOGOUT_REDIRECT_URL + '/signout';
};

const signup = () => {};

const getAccessToken = () => getApiAccessToken(msalInstance);

const app = (
  <React.StrictMode>
    <AuthProvider>
      <Provider store={rootStore}>
        <App  isAuthenticated={isAuthenticated}
              user={() => getUserInfo()}
              login={login}
              logout={logout}
              signup={signup}
              getAccessToken={getAccessToken}/>
      </Provider>
    </AuthProvider>
  </React.StrictMode>
);

ReactDOM.render(
  app,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
