import { createSelector } from 'reselect';

import { ILandCareChatState } from '../Reducers/LandCareChatSlice';
import { IMediaFile } from '../../Models/MediaFiles/IMediaFile';
import { IChatSummary } from '../../Models/LandCare/IChat';
import { RootState } from '../RootStore';

export const landCareChatSelector = (state: RootState) => state.landCareChat;

export const selectLandCareChatSummaries = createSelector(
    [landCareChatSelector],
    (state: ILandCareChatState) => state.chatSummaries as IChatSummary[]
);

export const selectLandCareEndOfRecords = createSelector(
    [landCareChatSelector],
    (state: ILandCareChatState) => state.chatSummaries[state.currentSummaryIndex] && state.chatSummaries[state.currentSummaryIndex].isEndOfRecords as any
);

export const selectLandCareAttachedFiles = createSelector(
    [landCareChatSelector],
    (state: ILandCareChatState) => state.attachedFiles as IMediaFile[]
);

export const selectCurrentSummaryIndex = createSelector(
    [landCareChatSelector],
    (state: ILandCareChatState) => state.currentSummaryIndex as number
);

export const selectCurrentGroupId = createSelector(
    [landCareChatSelector],
    (state: ILandCareChatState) => state.chatSummaries.length ? (state.chatSummaries[state.currentSummaryIndex].groupId || '') : '' as string
);