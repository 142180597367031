import React from "react";
import { AuthenticationResult, BrowserAuthError, InteractionRequiredAuthError, PublicClientApplication, RedirectRequest } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { ClientAuthError } from "msal";

import { msalConfig } from "./authConfig";
import b2cPolicies from './b2cpolicies';
import { MSAL_LOCALSTORAGE_KEY } from "../../Services/serviceHelper";
import apiConfig from "./apiConfig";

export const msalInstance = new PublicClientApplication(msalConfig);

const handleResponse = async (resp: any) => {
  console.log('resp ===', resp)
  if (resp && resp.account) {
    const key = resp.account.homeAccountId + '-' + resp.account.environment + '-' + resp.account.idTokenClaims.tid;

    await localStorage.setItem(MSAL_LOCALSTORAGE_KEY, key);
    await handleAuthResponse(resp);
  }
};

msalInstance.handleRedirectPromise().then(handleResponse).catch((error) => {
  console.error(error);
  if (error?.errorMessage?.indexOf("AADB2C90118") > -1) {
    if (b2cPolicies?.authorities?.forgotPassword) {
      try {
        const redirect = {
          authority: b2cPolicies.authorities.forgotPassword.authority,
          scopes: [] // TO BE ADDED LATER
        } as RedirectRequest;
        msalInstance.loginRedirect(redirect);
      } catch(err) {
        console.log(err);
      }
    }
  }
});

const handleAuthResponse = async (response: AuthenticationResult) => {
  console.log('response ===', response)
  
//   const apiUser = await SetApiToken(response);
//   const token = getToken();

//   if(apiUser.isNewUser) {
//     const isUserProfileSet = await hasUserProfile(token, apiUser);

//     if(!isUserProfileSet) {
//       const defaultUserProfile = await SetDefaultUserProfile(response, token, apiUser);

//       if(defaultUserProfile.isSuccess) {
//         navigate("/userprofile");
//       }
//     }
//   } else {
//     const hasUserProfileObj = await hasUserProfile(token, apiUser);
//   }

};

export const AuthProvider = ({children}: any) => {

    return (
      <MsalProvider instance={msalInstance}>
        {children}
      </MsalProvider>
    );
}

export const getApiAccessToken = async (msalInstance: PublicClientApplication) => {

  var accessToken = null as any;
  var tokenResponse = null as any;

  const accounts = await msalInstance.getAllAccounts();
  const account = accounts?.find((a: any) => a.idTokenClaims.aud === msalConfig.auth.clientId);

  if (account) {
    const request = {
      account: account,
      scopes: apiConfig.b2cScopes
    } as any;
    
    try {
      tokenResponse = await msalInstance.acquireTokenSilent(request);
      accessToken = tokenResponse.accessToken;
    } catch (error) {
      if (error instanceof InteractionRequiredAuthError) {
        // fallback to interaction when silent call fails
        tokenResponse = await msalInstance.acquireTokenRedirect(request).catch( err => console.log("InteractionRequiredAuthError on Redirect: ", err));
        console.log("Got Redirect TokenResponse as: ", tokenResponse);
        accessToken = tokenResponse.accessToken;
      }

      if( error instanceof BrowserAuthError) {
        // fallback to interaction when silent call fails
        tokenResponse = await msalInstance.acquireTokenRedirect(request).catch( err => console.log("BrowserAuthError on Redirect: ", err));
        console.log("Got Redirect TokenResponse as: ", tokenResponse);
        accessToken = tokenResponse.accessToken;
      }

      if( error instanceof ClientAuthError) {
        // fallback to interaction when silent call fails
        tokenResponse = await msalInstance.acquireTokenRedirect(request).catch( err => console.log("ClientAuthError on Redirect: ", err));
        console.log("Got Redirect TokenResponse as: ", tokenResponse);
        accessToken = tokenResponse.accessToken;
      }
    }
  }
  return accessToken;
};