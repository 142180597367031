import React, {useEffect, useRef, useState } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './AddLandholding.css';
import {Container, Row, Col, Button, Form, FormCheck, Spinner} from 'react-bootstrap';
import {reduxForm} from 'redux-form';
import LandholdingsApiService from '../../Services/LandholdingsApiService';
import MediaFiles from '../MediaFiles/MediaFiles';
import BaseMap from '../ArcGIS/BaseMap';
import { IUserProps } from '../../Models/Auth/AuthModels';
import { ILandholding } from '../../Models/Landholdings/Landholding';
import {
  CreateLandholding,
  UpdateLandholding,
  getReducerObj,
  UpdateForm,
  CopyAddress,
  GetCorrelationId,
  clearCurrentLandholding
} from '../../Store/ActionCreators/index';
import { connect, useDispatch, useSelector } from 'react-redux';
import checkmark from '../../Assets/checkmark-circle.svg';
import step from '../../Assets/nlyl-blue-circle.svg';
import polygonIcon from '../../Assets/Images/polygonIcon.jpg';
import Checkbox from '../Common/Checkbox/Checkbox';
import { ActionTypes } from '../../Store/Actions/types';

interface MatchParams {
  id: string;
}

interface IAddLandholdingProps extends RouteComponentProps<MatchParams>, IUserProps {
  currentLandHolding: ILandholding;
  CreateLandholding: Function;
  UpdateLandholding: Function;
  getReducerObj: Function;
  reduxForm: Function;
}

type AlertMessageState = {
  show: boolean;
  variant: string;
  heading: string;
  message: string;
};

let AddLandholding : React.FC<any> = (props) => {
  const { match: {params} } = props;
  const containerId = "gerxmedia";
  const module = "landholdings";
  const landholdingsApiService = new LandholdingsApiService();
  const [refreshMediafiles, callRefreshMediaFiles ] = useState(0);
  const [progressStep, setProgressStep] = useState(33);
  const [nameRequired, setNameRequired] = useState('');
  const [zipLength, setZipLength] = useState('');
  const [landholdingAddressValid, setLandholdingAddressValid] = useState(true);
  const [postalAddressValid, setPostalAddressValid] = useState(true);
  const [landholdingZipValid, setLandholdingZipValid] = useState(true);
  const [postalZipValid, setPostalZipValid] = useState(true);
  const [stateValid, setStateValid] = useState(true);
  const [postalStateValid, setPostalStateValid] = useState(true);
  const [validInput, setValid] = useState(true);
  const [isNextClicked, setIsNextClicked] = useState(false);  
  const [isCopyAddressChecked, setIsCopyAddressChecked] = useState(false);
  const [isMovingNext, setMovingNext] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const currentLandHolding = useSelector((state: any) => state.landHoldingsReducer.currentLandHolding);

  const dispatch = useDispatch();

  useEffect(() => {
    const refreshObj = async () => {
          setIsLoading(true);

          const accessToken = await props.getAccessToken();
          await props.GetCorrelationId(accessToken);

          setIsLoading(false);
    };

    refreshObj();
}, []);

  // show form fields and update progress bar based on next/previous
  const toggleStepper = (x: number): void => {
    setProgressStep(x)
  }

  const validateForm = async () => {
    if (isNextClicked && (props.currentLandHolding?.name?.trim().length === 0 || props.currentLandHolding?.name === undefined || props.currentLandHolding?.address.line1.length === 0 || props.currentLandHolding?.address.line1 === undefined || props.currentLandHolding?.address.postCode === undefined || props.currentLandHolding?.address.postCode.length !== 4 || !props.currentLandHolding?.address?.state || !props.currentLandHolding?.postalAddress?.state)) {
      if (props.currentLandHolding?.name?.length !== 0) {
        await setValid(true)
      }
      if (props.currentLandHolding?.address?.line1) {
        await setLandholdingAddressValid(true)
      }
      if (props.currentLandHolding?.address?.postCode || props.currentLandHolding?.address?.postCode?.length === 4) {
        await setZipLength("")
        await setLandholdingZipValid(true)
      }
      
      setStateValid(props.currentLandHolding?.address?.state ? true : false);
      setPostalStateValid(props.currentLandHolding?.postalAddress?.state ? true : false);
      
      if (props.currentLandHolding?.postalAddress?.line1) {
        await setPostalAddressValid(true)
      }
      if (props.currentLandHolding?.postalAddress?.postCode || props.currentLandHolding?.postalAddress?.postCode?.length === 4) {
        await setZipLength("")
        await setPostalZipValid(true)
      }
      if (props.currentLandHolding?.name?.length === 0 || props.currentLandHolding?.name === undefined) {
        await setValid(false)
        await setNameRequired('Missing required fields.')
      }
      if (!props.currentLandHolding?.address?.line1) {
        await setLandholdingAddressValid(false)
        await setNameRequired('Missing required fields.')
      }
      if (!props.currentLandHolding?.address?.postCode || props.currentLandHolding?.address?.postCode?.length !== 4) {
        await setZipLength("Zip code must be 4 numbers long.")
        await setLandholdingZipValid(false)
        await setNameRequired('Missing required fields.')
      }
      if (!props.currentLandHolding?.postalAddress?.line1) {
        await setPostalAddressValid(false)
        await setNameRequired('Missing required fields.')
      }
      if (!props.currentLandHolding?.postalAddress?.postCode || props.currentLandHolding?.postalAddress?.postCode?.length !== 4) {
        await setZipLength("Zip code must be 4 numbers long.")
        await setPostalZipValid(false)
        await setNameRequired('Missing required fields.')
      }
    } else {
      await setValid(true)
      await setLandholdingZipValid(true)
      await setPostalZipValid(true)

      await setLandholdingAddressValid(true)
      await setPostalAddressValid(true)
      await setNameRequired("")
      setStateValid(true)
      setPostalStateValid(true)
    }
  }

  const validateFormNext = async () => {
    setIsNextClicked(true);
    setMovingNext(true);

    const { line1, postCode, state } = props.currentLandHolding.address || {};

    if (
      props.currentLandHolding?.name === undefined || 
      props.currentLandHolding?.name?.trim().length === 0 || 
      (!line1 || !postCode || !state) ||
      (props.currentLandHolding?.address? props.currentLandHolding?.address.line1 === undefined : false) || 
      (props.currentLandHolding?.address? props.currentLandHolding?.address.line1? props.currentLandHolding?.address.line1.length === 0 : false : false) || 
      (props.currentLandHolding?.address? props.currentLandHolding?.address.postCode === undefined : false) || 
      (props.currentLandHolding?.address? props.currentLandHolding?.address.postCode ? props.currentLandHolding?.address.postCode.length !== 4 : false : false)
    ) {
      if (props.currentLandHolding?.name ? props.currentLandHolding?.name?.trim().length !== 0 : false) {
        await setValid(true)
      }
      if (props.currentLandHolding?.address? props.currentLandHolding?.address.line1 : false) {
        await setLandholdingAddressValid(true)
      }
      if (
        (props.currentLandHolding?.address? props.currentLandHolding?.address.postCode : false) || 
        (props.currentLandHolding?.address? props.currentLandHolding?.address.postCode.length === 4: false)
      ) {
        await setZipLength("")
        await setLandholdingZipValid(true)
      }
      if (props.currentLandHolding?.postalAddress? props.currentLandHolding?.postalAddress.line1 : false) {
        await setPostalAddressValid(true)
      }
      if (
        (props.currentLandHolding?.postalAddress? props.currentLandHolding?.postalAddress.postCode : false) || 
        (props.currentLandHolding?.postalAddress? props.currentLandHolding?.postalAddress.postCode.length === 4: false)
      ) {
        await setZipLength("")
        await setPostalAddressValid(true)
      }
      if (props.currentLandHolding?.name === undefined || props.currentLandHolding?.name?.trim().length === 0) {
        await setValid(false)
        await setNameRequired('Missing required fields.')
      }
      if (props.currentLandHolding?.address === undefined || !props.currentLandHolding?.address.line1) {
        await setLandholdingAddressValid(false)
        await setNameRequired('Missing required fields.')
      }
      if (props.currentLandHolding?.address === undefined || !props.currentLandHolding?.address.postCode || props.currentLandHolding?.address.postCode.length !== 4) {
        await setZipLength("Zip code must be 4 numbers long.")
        await setLandholdingZipValid(false)
        await setNameRequired('Missing required fields.')
      }
      if (props.currentLandHolding?.postalAddress === undefined || !props.currentLandHolding?.postalAddress.line1) {
        await setPostalAddressValid(false)
        await setNameRequired('Missing required fields.')
      }
      setPostalZipValid(true);
      if (props.currentLandHolding?.postalAddress === undefined || !props.currentLandHolding?.postalAddress?.postCode || props.currentLandHolding?.postalAddress?.postCode?.length !== 4) {
        await setZipLength("Zip code must be 4 numbers long.")
        await setPostalZipValid(false)
        await setNameRequired('Missing required fields.')
      }
      setStateValid(true);
      if (!props.currentLandHolding?.address?.state) {
        setStateValid(false);
        setNameRequired('Missing required fields.');
      }
      setPostalStateValid(true);
      if (!props.currentLandHolding?.postalAddress?.state) {
        setPostalStateValid(false);
        setNameRequired('Missing required fields.');
      }
    } else {
      await setValid(true)
      await setLandholdingZipValid(true)
      await setPostalZipValid(true)
      await setLandholdingAddressValid(true)
      await setPostalAddressValid(true)
      await setNameRequired("")
      await setZipLength("")
      if (props.currentLandHolding?._id && props.currentLandHolding?._id.length > 0) {
        const accessToken = await props.getAccessToken();
        await props.UpdateLandholding(accessToken, props.currentLandHolding)
        toggleStepper(66)
      } else {
        const accessToken = await props.getAccessToken();
        await props.CreateLandholding(accessToken, props.currentLandHolding)
        toggleStepper(66)
      }
    }
    setMovingNext(false);
  }

  const clearLandholding = async () => {
    await props.clearCurrentLandholding();
  }

  const saveLandholding = async () => { 
    const accessToken = await props.getAccessToken(); 
    props.getReducerObj()
    await props.UpdateLandholding(accessToken, props.currentLandHolding);
    await clearLandholding();
  }

  const handleFileChange = async (event: any) => {
    event.preventDefault();

    let files = event.target.files;
    if(files && files.length > 0)
    {
      const accessToken = await props.getAccessToken();
      const moduleRefId = params.id ? params.id : props.initialValues._id;
      await landholdingsApiService.uploadMediaFilesById(accessToken, containerId, module, moduleRefId, files);
      callRefreshMediaFiles((refreshMediafiles: any) => refreshMediafiles + 1);
    }
    else
    {
      console.log("no files selected");
    }
  }

  //*****CUSTOM BUILT CHANGE HANDLERS CONNECTING DIRECTLY TO REDUX STORE:*****
  
  const updateCheckBox = async (id: number, event: any) => {
    const accessToken = await props.getAccessToken()
    props.getReducerObj();
    let checkStatusBio = event.target.value
    if (checkStatusBio == true || checkStatusBio == 'true'){
      checkStatusBio = false;
    } else if (!checkStatusBio || checkStatusBio === 'false') {
      checkStatusBio = true;
    }
    await props.UpdateForm(accessToken, props.initialValues, id, checkStatusBio, props.currentLandHolding)
  };

  const postalline1 = useRef(currentLandHolding?.address?.line1 as any);
  const postalPostCode = useRef(currentLandHolding?.address?.postCode as any);

  const updateText = async (id: number, event: any) => {
    const accessToken = await props.getAccessToken() 
    props.getReducerObj()
    const initialValues = { ...props.initialValues, correlationId: currentLandHolding.correlationId };
    await props.UpdateForm(accessToken, initialValues, id, event.target.value, props.currentLandHolding);
  };

  /* ***** ***** */

  const handleCopyAddress = (isChecked: boolean) => {
    setIsCopyAddressChecked(isChecked);
    dispatch<CopyAddress>({ type: ActionTypes.copyAddress, payload: isChecked });
    if (isChecked) {
      postalline1.current.value = currentLandHolding?.address?.line1 || '';
      postalPostCode.current.value = currentLandHolding?.address?.postCode || '';
    }
    else {
      postalline1.current.value = '';
      postalPostCode.current.value = '';
    }
    setPostalAddressValid(landholdingAddressValid); 
    setPostalZipValid(landholdingZipValid);
    setPostalStateValid(stateValid);
  };

  return (
    <>
      <Container fluid>
        <Row>         
          <Col>
          <form>
            <Container className="text-left">
              <Row>
                <Col className="margin-btm-common">
                  <div className="center-title"><h4>Form Progress</h4></div>
                  <div className="progress-bar-titles">
                    <div className="step-one-location-title">Step 1</div>
                    <div className="separator"></div>
                    <div className="step-three-location-title">Step 2</div>
                    <div className="separator"></div>
                    <div className="step-five-location-title">Step 3</div>
                    <div className="separator"></div>
                    <div className="step-seven-location-title">Completed</div>
                  </div>
                  <div className="progress-bar">
                    { progressStep === 33 || progressStep === 66 || progressStep === 100 ? (<div className="step-one-location"><img
                      className="imgs"
                      src={checkmark}
                      alt="checkmark"
                      height="32"
                      width="32"
                    /></div>) : (<div className="step-one-location"><img
                      className="imgs"
                      src={step}
                      alt="step"
                      height="32"
                      width="32"
                    /></div>)}
                    <div className="step-two-location"></div>
                    { progressStep === 66 || progressStep === 100 ? (<div className="step-three-location"><img
                      className="imgs"
                      src={checkmark}
                      alt="checkmark"
                      height="32"
                      width="32"
                    /></div>) : (<div className="step-three-location"><img
                      className="imgs"
                      src={step}
                      alt="step"
                      height="32"
                      width="32"
                    /></div>)}
                    <div className="step-four-location"></div>
                    { progressStep === 100 ? (<div className="step-five-location"><img
                      className="imgs"
                      src={checkmark}
                      alt="checkmark"
                      height="32"
                      width="32"
                    /></div>) : (<div className="step-five-location"><img
                      className="imgs"
                      src={step}
                      alt="step"
                      height="32"
                      width="32"
                    /></div>)}
                    <div className="step-six-location"></div>
                      <div className="step-seven-location"><img
                        className="imgs"
                        src={step}
                        alt="step"
                        height="32"
                        width="32"
                      /></div>
                  </div>
                </Col>
              </Row>
              
              {progressStep === 33 && (
                <React.Fragment>
                  <Row>
                  <Col>
                    <div>
                      <h3>Create or Edit Property</h3>
                      <br></br>
                      <p>The information provided will help us to assess the projects that are feasible on the described land. The more information we have, the better will be our assessment on potential projects</p>
                      
                      <div>
                        <Form.Group controlId="name">
                          <Form.Label>Give this property a name to identify it:</Form.Label>
                          <Form.Control type="text" placeholder="Property Name" defaultValue={props.initialValues.namefield} onKeyUp={(event: any) => updateText(1, event)} className={`${validInput === true ? '' : 'error-input'}`} onBlur={validateForm} autoComplete="none" />
                        </Form.Group>
                      </div>
                      <br/><br/>
                    </div>
                  </Col>
                </Row>
                  <Row>
                    <Col>
                      <div>
                        <h6>Draw an outline of the property.</h6>
                        <ul>
                          <li>Click and drag to move the map around to desired location; scroll to zoom in and out.</li>
                          <li>Create a polygon (Note: Property name is required) by clicking on the <img src={polygonIcon} alt="polygon" /> icon.</li>
                          <li>Click on the desired locations on the map to make property boundary points.</li>
                          <li>Close the boundary by clicking the start point, and the polygon will be auto saved.</li>
                          <li>Polygons that are created on the map will also be listed under it with address and area in hectares.</li>
                          <li>You can use the icons to the right of the listings (under the map) to locate the polygon on the map or delete it.</li>
                        </ul>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col /*xs={10} sm={10} md={10} lg={10} xl={10}*/>
                      <div id="mapContainer" className="map-size">
                        <BaseMap listGeometryItems={props.currentLandHolding ? props.currentLandHolding?.mapGraphItems : []} currentUser={props.currentLandHolding !== undefined ? props.currentLandHolding?._id : ''} getAccessToken={() => props.getAccessToken()} thisItem={props.initialValues} thisObj={props.currentLandHolding} />
                      </div><br/><br/><br/><br/><br/>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div>
                        <h6>Address Information</h6>
                        <div>
                          <Form.Group controlId="address.line1">
                            <Form.Label>Property Address</Form.Label>
                            <Form.Control type="text" placeholder="e.g. Road number &amp; name" defaultValue={props.initialValues.line1} onKeyUp={(event: any) => updateText(2, event)} className={`${landholdingAddressValid === true ? '' : 'error-input'}`} onBlur={validateForm} disabled={isCopyAddressChecked}
                            />
                          </Form.Group>
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Group controlId="address.postCode">
                        <Form.Label>Postcode {zipLength}</Form.Label>
                        <Form.Control type="number" placeholder="Enter Postcode" defaultValue={props.initialValues.postCode} onKeyUp={(event: any) => updateText(3, event)} className={`${landholdingZipValid === true ? '' : 'error-input'}`} onBlur={validateForm} disabled={isCopyAddressChecked} />
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group controlId="address.state">
                        <Form.Label>State</Form.Label>
                        <Form.Control as="select" onChange={(event: any) => updateText(4, event)} disabled={isCopyAddressChecked} className={`${stateValid ? '' : 'error-input'}`} onBlur={validateForm}>
                          <option value="">(Please select State)</option>
                          <option selected={props.initialValues.state === "Australian Capital Territory" ? true : false}>Australian Capital Territory</option>
                          <option selected={props.initialValues.state === "Northern Territory" ? true : false}>Northern Territory</option>
                          <option selected={props.initialValues.state === "New South Wales" ? true : false}>New South Wales</option>
                          <option selected={props.initialValues.state === "Queensland" ? true : false}>Queensland</option>
                          <option selected={props.initialValues.state === "South Australia" ? true : false}>South Australia</option>
                          <option selected={props.initialValues.state === "Tasmania" ? true : false}>Tasmania</option>
                          <option selected={props.initialValues.state === "Western Australia" ? true : false}>Western Australia</option>
                          <option selected={props.initialValues.state === "Victoria" ? true : false}>Victoria</option>
                        </Form.Control>
                      </Form.Group>
                    </Col>
                    <Col></Col>
                  </Row>
                  <Row>
                    <Col>
                      <Checkbox label="Copy Property Address to Postal Address below" isChecked={isCopyAddressChecked} onChange={handleCopyAddress} />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div>
                        <Form.Group controlId="address.postalLine1">
                          <Form.Label>Postal Address</Form.Label>
                          <Form.Control type="text" placeholder="e.g. Road number &amp; name" ref={postalline1} defaultValue={currentLandHolding?.postalAddress?.line1} onKeyUp={(event: any) => updateText(5, event)} className={`${postalAddressValid === true ? '' : 'error-input'}`} onBlur={validateForm} disabled={isCopyAddressChecked}
                          />
                        </Form.Group>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Group controlId="address.postalPostCode">
                        <Form.Label>Postcode {zipLength}</Form.Label>
                        <Form.Control type="number" placeholder="Enter Postcode" ref={postalPostCode} defaultValue={currentLandHolding?.postalAddress?.postCode} onKeyUp={(event: any) => updateText(6, event)} className={`${postalZipValid === true ? '' : 'error-input'}`} onBlur={validateForm} disabled={isCopyAddressChecked} />
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group controlId="address.postalState">
                        <Form.Label>State</Form.Label>
                        <Form.Control as="select" onChange={(event: any) => updateText(7, event)} disabled={isCopyAddressChecked} className={`${postalStateValid ? '' :  'error-input'}`} onBlur={validateForm}>
                          <option value="">(Please select State)</option>
                          <option selected={currentLandHolding?.postalAddress?.state === "Australian Capital Territory" ? true : false}>Australian Capital Territory</option>
                          <option selected={currentLandHolding?.postalAddress?.state === "Northern Territory" ? true : false}>Northern Territory</option>
                          <option selected={currentLandHolding?.postalAddress?.state === "New South Wales" ? true : false}>New South Wales</option>
                          <option selected={currentLandHolding?.postalAddress?.state === "Queensland" ? true : false}>Queensland</option>
                          <option selected={currentLandHolding?.postalAddress?.state === "South Australia" ? true : false}>South Australia</option>
                          <option selected={currentLandHolding?.postalAddress?.state === "Tasmania" ? true : false}>Tasmania</option>
                          <option selected={currentLandHolding?.postalAddress?.state === "Western Australia" ? true : false}>Western Australia</option>
                          <option selected={currentLandHolding?.postalAddress?.state === "Victoria" ? true : false}>Victoria</option>
                        </Form.Control>
                      </Form.Group>
                    </Col>
                    <Col></Col>
                  </Row>
                  <Row>
                    <Col className="margin-btm-common">
                      <Link to={`/`} onClick={clearLandholding}>
                        <Button className="float-left btn-secondary">Cancel</Button>
                      </Link>
                    </Col>
                    <Col>
                      {nameRequired}
                    </Col>
                    <Col>
                      <Button className="margin-btm-common float-right" onClick={validateFormNext} disabled={isMovingNext}>
                        Next
                        { !isMovingNext ? <></> :
                          <>
                            &nbsp;<Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                          </>
                        }
                      </Button>
                    </Col>
                  </Row>
                </React.Fragment> 
              )}
              {progressStep === 66 && (
                <React.Fragment>
                  <Row>
                    <Col>
                      <div>
                        <h3>Create or Edit Property</h3><br/>
                        <div>
                          <Form.Group controlId="name">
                            <Form.Label>Property Name:</Form.Label>
                            <h3>{props.initialValues.namefield}</h3>
                          </Form.Group>
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col><br/><br/>
                      <div>
                        <h6>Current Use(s)</h6>
                        <p>Please let us know about the current land usage of your property </p>
                        <div>
                          <Form.Group controlId="currentUseDescription">
                            <Form.Label>Description</Form.Label>
                            <Form.Control as="textarea" rows={3} placeholder="Notes about land usage for the project workers" defaultValue={props.initialValues.currentUseDescription} onKeyUp={(event: any) => updateText(9, event)}/>
                          </Form.Group>                            
                        </div>
                      </div><br/><br/>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div>
                        <h6>Style(s) of Projects</h6>
                        <p>What type of projects would you be interested in doing on your property? Please select your preferences so that we can advise you.</p>
                        <div>
                          <div>
                            <Form.Group controlId="isConservationBiodiversity">
                              <FormCheck>
                                <FormCheck.Input type="checkbox" checked={props.initialValues.isConservationBiodiversity} defaultValue={props.initialValues.isConservationBiodiversity} onClick={(event: any) => updateCheckBox(10, event)} />
                                <FormCheck.Label><strong>Conservation / Biodiversity</strong> - protecting existing native vegetation on your property</FormCheck.Label>
                              </FormCheck>
                            </Form.Group>
                          </div>
                          <div>
                            <Form.Group controlId="isConversionOfLowProductiveAndDegradedLand">
                              
                              <FormCheck>
                              <FormCheck.Input type="checkbox" checked={props.initialValues.isConversionOfLowProductiveAndDegradedLand} defaultValue={props.initialValues.isConversionOfLowProductiveAndDegradedLand} onClick={(event: any) => updateCheckBox(11, event)} />
                                <FormCheck.Label><strong>Conversion of low productive and degraded land to conservation</strong> - activities include planting and or management of native regrowth</FormCheck.Label>
                              </FormCheck>
                            </Form.Group>
                          </div>
                          <div>
                            <Form.Group controlId="isRevegetationBlockPlanting">
                              <FormCheck>
                                <FormCheck.Input type="checkbox" checked={props.initialValues.isRevegetationBlockPlanting} defaultValue={props.initialValues.isRevegetationBlockPlanting} onClick={(event: any) => updateCheckBox(12, event)} />
                                <FormCheck.Label><strong>Revegetation Block Planting</strong> - planting an area of any shape</FormCheck.Label>
                              </FormCheck>                              
                            </Form.Group>
                          </div>
                          <div>
                            <Form.Group controlId="isRevegerationLinearBeltPlanting">
                              <FormCheck>
                                <FormCheck.Input type="checkbox" checked={props.initialValues.isRevegerationLinearBeltPlanting} defaultValue={props.initialValues.isRevegerationLinearBeltPlanting} onClick={(event: any) => updateCheckBox(13, event)} /> 
                                <FormCheck.Label><strong>Revegetation Linear / Belt Planting</strong> - planting windbreak-line strips of vegetation across a paddock. Can include shelter and palatable fodder.</FormCheck.Label>
                              </FormCheck>                            
                            </Form.Group>
                          </div>
                          <div>
                            <Form.Group controlId="isSaltLandsPlanting">
                              <FormCheck>
                                <FormCheck.Input type="checkbox" checked={props.initialValues.isSaltLandsPlanting} defaultValue={props.initialValues.isSaltLandsPlanting} onClick={(event: any) => updateCheckBox(14, event)} /> 
                                <FormCheck.Label><strong>Salt Lands Planting</strong> - planting salt tolerant species for shade, shelter and fodder in agricultural systems</FormCheck.Label>
                              </FormCheck>                            
                            </Form.Group>
                          </div>                       
                          <div>
                            <Form.Group controlId="isPaddockTrees">
                              <FormCheck>
                                <FormCheck.Input type="checkbox" checked={props.initialValues.isPaddockTrees} defaultValue={props.initialValues.isPaddockTrees} onClick={(event: any) => updateCheckBox(15, event)} /> <strong>Paddock Trees</strong> - planting scattered shelter trees throughout grazing paddocks without interrupting stocking or rotations
                                <FormCheck.Label></FormCheck.Label>
                              </FormCheck>
                            </Form.Group>
                          </div>
                          <div>
                            <Form.Group controlId="isCarbonOffset">
                              <FormCheck>
                                <FormCheck.Input type="checkbox" checked={props.initialValues.isCarbonOffset} defaultValue={props.initialValues.isCarbonOffset} onClick={(event: any) => updateCheckBox(16, event)} />
                                <FormCheck.Label><strong>Carbon Offset</strong> - register the planting site to create carbon credits</FormCheck.Label>
                              </FormCheck>
                            </Form.Group>
                          </div>
                          <div>
                            <Form.Group controlId="isWaterQualityOffset">
                              <FormCheck>
                                <FormCheck.Input type="checkbox" checked={props.initialValues.isWaterQualityOffset} defaultValue={props.initialValues.isWaterQualityOffset} onClick={(event: any) => updateCheckBox(17, event)} /> 
                                <FormCheck.Label><strong>Water Quality Offset</strong> - register the project site for Reef Credits (only applicable in Great Barrier Reef Catchments)</FormCheck.Label>
                              </FormCheck>
                              
                            </Form.Group>
                          </div>
                          <div>
                            <Form.Group controlId="isRiverRiparianRestoration">
                              <FormCheck>
                                <FormCheck.Input type="checkbox" checked={props.initialValues.isRiverRiparianRestoration} defaultValue={props.initialValues.isRiverRiparianRestoration} onClick={(event: any) => updateCheckBox(18, event)} /> 
                                <FormCheck.Label><strong>River / Riparian Restoration</strong> - protection and/or management of river and riparian areas activities. May include fencing, planting, alternative stock water and erosion control structures</FormCheck.Label>
                              </FormCheck>                              
                            </Form.Group>
                          </div>
                          <div>
                            <Form.Group controlId="isWetlandRestoration">
                              <FormCheck>
                                <FormCheck.Input type="checkbox" checked={props.initialValues.isWetlandRestoration} defaultValue={props.initialValues.isWetlandRestoration} onClick={(event: any) => updateCheckBox(19, event)} /> 
                                <FormCheck.Label><strong>Wetland Restoration / Management</strong> - establishing or managing wetlands to treat water runoff and or improve biodiversity</FormCheck.Label>
                              </FormCheck>
                              </Form.Group>
                          </div>
                          <div>
                            <Form.Group controlId="isErosionControl">
                              <FormCheck>
                                <FormCheck.Input type="checkbox" checked={props.initialValues.isErosionControl} defaultValue={props.initialValues.isErosionControl} onClick={(event: any) => updateCheckBox(20, event)} /> 
                                <FormCheck.Label><strong>Erosion Control</strong> - inclusive of remediation and or land practice change activities. May include planting, earthworks, land practice change (e.g., destocking), fencing, erosion control structures</FormCheck.Label>
                              </FormCheck>
                            </Form.Group>
                          </div>
                          <div>
                            <Form.Group controlId="isOther">
                              <FormCheck>
                                <FormCheck.Input type="checkbox" checked={props.initialValues.landholdingStyleOther} defaultValue={props.initialValues.landholdingStyleOther} onClick={(event: any) => updateCheckBox(21, event)} /> 
                                <FormCheck.Label><strong>Other Style</strong> - if the project style is not listed check here and provide details below</FormCheck.Label>
                              </FormCheck>
                              <Form.Control type="text" placeholder="Project style if it isn't listed" defaultValue={props.initialValues.projectStyleDescription} onKeyUp={(event: any) => updateText(22, event)} disabled={!props.initialValues.landholdingStyleOther} />
                            </Form.Group>
                          </div>
                        </div><br/>
                        <div><br/>
                          <Row>
                            <Col>
                              What percentage of your property is available for restoration?
                              <div className="sliderContainer">
                                <input className="slider" type="range" defaultValue={props.initialValues.availableSizePercentage} onChange={(event: any) => updateText(23, event)} />
                                <div className="slider-tool">{props.initialValues.availableSizePercentage}%</div>
                              </div>
                            </Col>
                            <Col></Col>
                          </Row>
                        </div><br/><br/>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div>
                        <h6>Soil Description</h6>
                        <div>
                          <div>
                            <Form.Group controlId="isClayAndSilts">
                              <FormCheck>
                                <FormCheck.Input type="checkbox" checked={props.initialValues.isClayAndSilts} defaultValue={props.initialValues.isClayAndSilts} onClick={(event: any) => updateCheckBox(24, event)} />
                                <FormCheck.Label>Clay and Silts</FormCheck.Label>
                              </FormCheck>
                              </Form.Group>
                          </div>
                          <div>
                            <Form.Group controlId="isSand">
                              <FormCheck>
                                <FormCheck.Input type="checkbox" checked={props.initialValues.isSand} defaultValue={props.initialValues.isSand} onClick={(event: any) => updateCheckBox(25, event)} /> 
                                <FormCheck.Label>Sand</FormCheck.Label>
                                </FormCheck>
                            </Form.Group>
                          </div>
                          <div>
                            <Form.Group controlId="isGravel">
                              <FormCheck>
                                <FormCheck.Input type="checkbox" checked={props.initialValues.isGravel} defaultValue={props.initialValues.isGravel} onClick={(event: any) => updateCheckBox(26, event)} /> 
                                <FormCheck.Label>Gravel</FormCheck.Label>
                              </FormCheck>
                              </Form.Group>
                          </div>
                          <div>
                            <Form.Group controlId="isLoam">
                              <FormCheck>
                                <FormCheck.Input type="checkbox" checked={props.initialValues.isLoam} defaultValue={props.initialValues.isLoam} onClick={(event: any) => updateCheckBox(27, event)} /> 
                                <FormCheck.Label>Loam</FormCheck.Label>
                              </FormCheck>                            
                            </Form.Group>
                          </div>
                          <div>
                            <Form.Group controlId="isRocky">
                              <FormCheck>
                                <FormCheck.Input type="checkbox" checked={props.initialValues.isRocky} defaultValue={props.initialValues.isRocky} onClick={(event: any) => updateCheckBox(28, event)} /> 
                                <FormCheck.Label>Rocky</FormCheck.Label>
                              </FormCheck>                              
                            </Form.Group>
                          </div>                      
                          <div>
                            <Form.Group controlId="isOutCroppingRock">
                              <FormCheck>
                                <FormCheck.Input type="checkbox" checked={props.initialValues.isOutCroppingRock} defaultValue={props.initialValues.isOutCroppingRock} onClick={(event: any) => updateCheckBox(29, event)} /> 
                                <FormCheck.Label>Outcropping Rock</FormCheck.Label>
                              </FormCheck>                              
                            </Form.Group>
                          </div>
                          <div>
                            <Form.Group controlId="isSDOther">
                              <FormCheck>
                                <FormCheck.Input type="checkbox" checked={props.initialValues.soilAndDescriptionOther} defaultValue={props.initialValues.soilAndDescriptionOther} onClick={(event: any) => updateCheckBox(30, event)} />
                                <FormCheck.Label>Other Soil</FormCheck.Label>
                              </FormCheck>
                              <Form.Control type="text" placeholder="Type of ground if it isn't listed" onKeyUp={(event: any) => updateText(31, event)} disabled={!props.initialValues.soilAndDescriptionOther} defaultValue={props.initialValues.soilTypeDescription} />
                            </Form.Group>
                          </div>
                        </div>
                      </div><br/><br/>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="margin-btm-common">
                      <Link to={`/`} onClick={clearLandholding}>
                        <Button className="float-left btn-secondary">Cancel</Button>
                      </Link>
                    </Col>
                    <Col>
                      <Button className="margin-btm-common float-right" onClick={() => toggleStepper(100)}>Next</Button>
                      <Button className="margin-btm-common float-right margin-right-prev" onClick={() => toggleStepper(33)}>Previous</Button>
                    </Col>
                  </Row>
                </React.Fragment>
              )}
              {progressStep === 100 && (
                <React.Fragment>
                  <Row>
                    <Col>
                      <div>
                        <h3>Create or Edit Property</h3><br/>
                        <p>In this section, please share any files or additional information that could help us assess your property better.<br></br>For example, images of erosion, previous projects, plans, buildings, access to water, cadastral files or anything else that will help us learn more about the condition of your property.</p>
                        <div>
                          <Form.Group controlId="name">
                            <Form.Label>Property Name:</Form.Label>
                            <h3>{props.initialValues.namefield}</h3>
                          </Form.Group>
                        </div>
                        <br/>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div>
                        <h6>Upload files</h6>
                        <div>
                        <Form.File name="file" label="Select file(s)" custom onChange={handleFileChange} id="mediafiles" feedbackTooltip />
                        </div>
                        <div>                          
                          <MediaFiles {...props} containerId={containerId} module={module} folderId={props.initialValues._id ? props.initialValues._id : ''} refresh={refreshMediafiles} />
                        </div>
                        <br/><br/><br/><br/>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div>
                        <h6>Further Information or Comments</h6>
                        <div>
                          <Form.Group controlId="furtherInformationOrComments">
                            <Form.Control as="textarea" rows={4} placeholder="Please include any additional comments here." defaultValue={props.initialValues.furtherInformationOrComments} onKeyUp={(event: any) => updateText(32, event)} />
                          </Form.Group>
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Link to={`/`} onClick={clearLandholding}>
                        <Button className="float-left margin-btm-common" variant="danger">Cancel</Button>
                      </Link>
                    </Col>
                    <Col>
                      {nameRequired}
                    </Col>
                    <Col>
                      <Link to={`/landportal/thankyou`}>
                        <Button className="float-right margin-btm-common" variant="success" onClick={saveLandholding}>Save</Button>
                      </Link>
                      <Button className="float-right margin-right-prev margin-btm-common" onClick={() => toggleStepper(66)}>Previous</Button>
                    </Col>
                  </Row>  
                </React.Fragment>
              )}           
            </Container>
          </form>
          </Col>
        </Row>
      </Container>
    </>      
  );
}

const mapStateToProps = (state: any) => ({
  //nested state because using combineReducers in reducer index:
  currentLandHolding: state.landHoldingsReducer.currentLandHolding,
  initialValues: {
    _id: state.landHoldingsReducer.currentLandHolding ? state.landHoldingsReducer.currentLandHolding?._id : '',
    userId: state.landHoldingsReducer.currentLandHolding ? state.landHoldingsReducer.currentLandHolding?.userId : '',
    namefield: state.landHoldingsReducer.currentLandHolding ? state.landHoldingsReducer.currentLandHolding?.name : '',
    fullAddress: state.landHoldingsReducer.currentLandHolding ? state.landHoldingsReducer.currentLandHolding?.fullAddress : '',

    line1: state.landHoldingsReducer.currentLandHolding ? state.landHoldingsReducer.currentLandHolding?.address? state.landHoldingsReducer.currentLandHolding?.address.line1 : '' : '',
    postCode: state.landHoldingsReducer.currentLandHolding ? state.landHoldingsReducer.currentLandHolding?.address? state.landHoldingsReducer.currentLandHolding?.address.postCode : ''  : '',
    state: state.landHoldingsReducer.currentLandHolding ? state.landHoldingsReducer.currentLandHolding?.address? state.landHoldingsReducer.currentLandHolding?.address.state : '' : '',
    
    streetNumber: state.landHoldingsReducer.currentLandHolding ? state.landHoldingsReducer.currentLandHolding?.streetNumber : '',
    streetType: state.landHoldingsReducer.currentLandHolding ? state.landHoldingsReducer.currentLandHolding?.streetType : '',
    streetName: state.landHoldingsReducer.currentLandHolding ? state.landHoldingsReducer.currentLandHolding?.streetName : '',
    suburb: state.landHoldingsReducer.currentLandHolding ? state.landHoldingsReducer.currentLandHolding?.suburb : '',
    
    country: state.landHoldingsReducer.currentLandHolding ? state.landHoldingsReducer.currentLandHolding?.country : '',
    lat: state.landHoldingsReducer.currentLandHolding ? state.landHoldingsReducer.currentLandHolding?.lat : '',
    lng: state.landHoldingsReducer.currentLandHolding ? state.landHoldingsReducer.currentLandHolding?.lng : '',

    postalLine1: state.landHoldingsReducer.currentLandHolding ? state.landHoldingsReducer.currentLandHolding?.postalAddress? state.landHoldingsReducer.currentLandHolding?.postalAddress.line1 : '' : '',
    postalPostCode: state.landHoldingsReducer.currentLandHolding ? state.landHoldingsReducer.currentLandHolding?.postalAddress? state.landHoldingsReducer.currentLandHolding?.postalAddress.postCode : ''  : '',
    postalState: state.landHoldingsReducer.currentLandHolding ? state.landHoldingsReducer.currentLandHolding?.postalAddress? state.landHoldingsReducer.currentLandHolding?.postalAddress.state : '' : '',
    
    cadastralInformation: state.landHoldingsReducer.currentLandHolding ? state.landHoldingsReducer.currentLandHolding?.cadastralInformation : '',
    currentUseDescription: state.landHoldingsReducer.currentLandHolding ? state.landHoldingsReducer.currentLandHolding?.currentUseDescription : '',

    isConservationBiodiversity: state.landHoldingsReducer.currentLandHolding ? state.landHoldingsReducer.currentLandHolding?.projectStyles? state.landHoldingsReducer.currentLandHolding?.projectStyles.isConservationBiodiversity: false : false,
    isConversionOfLowProductiveAndDegradedLand: state.landHoldingsReducer.currentLandHolding ? state.landHoldingsReducer.currentLandHolding?.projectStyles? state.landHoldingsReducer.currentLandHolding?.projectStyles.isConversionOfLowProductiveAndDegradedLand : false : false,
    isRevegetationBlockPlanting: state.landHoldingsReducer.currentLandHolding ? state.landHoldingsReducer.currentLandHolding?.projectStyles? state.landHoldingsReducer.currentLandHolding?.projectStyles.isRevegetationBlockPlanting: false : false,
    isRevegerationLinearBeltPlanting: state.landHoldingsReducer.currentLandHolding ? state.landHoldingsReducer.currentLandHolding?.projectStyles? state.landHoldingsReducer.currentLandHolding?.projectStyles.isRevegerationLinearBeltPlanting: false : false,
    isSaltLandsPlanting: state.landHoldingsReducer.currentLandHolding ? state.landHoldingsReducer.currentLandHolding?.projectStyles? state.landHoldingsReducer.currentLandHolding?.projectStyles.isSaltLandsPlanting: false : false,
    isPaddockTrees: state.landHoldingsReducer.currentLandHolding ? state.landHoldingsReducer.currentLandHolding?.projectStyles? state.landHoldingsReducer.currentLandHolding?.projectStyles.isPaddockTrees: false : false,
    isCarbonOffset: state.landHoldingsReducer.currentLandHolding ? state.landHoldingsReducer.currentLandHolding?.projectStyles? state.landHoldingsReducer.currentLandHolding?.projectStyles.isCarbonOffset: false : false,
    isWaterQualityOffset: state.landHoldingsReducer.currentLandHolding ? state.landHoldingsReducer.currentLandHolding?.projectStyles? state.landHoldingsReducer.currentLandHolding?.projectStyles.isWaterQualityOffset: false : false,
    isRiverRiparianRestoration: state.landHoldingsReducer.currentLandHolding ? state.landHoldingsReducer.currentLandHolding?.projectStyles? state.landHoldingsReducer.currentLandHolding?.projectStyles.isRiverRiparianRestoration: false : false,
    isWetlandRestoration: state.landHoldingsReducer.currentLandHolding ? state.landHoldingsReducer.currentLandHolding?.projectStyles? state.landHoldingsReducer.currentLandHolding?.projectStyles.isWetlandRestoration: false : false,
    isErosionControl: state.landHoldingsReducer.currentLandHolding ? state.landHoldingsReducer.currentLandHolding?.projectStyles? state.landHoldingsReducer.currentLandHolding?.projectStyles.isErosionControl: false : false,
    landholdingStyleOther: state.landHoldingsReducer.currentLandHolding ? state.landHoldingsReducer.currentLandHolding?.projectStyles? state.landHoldingsReducer.currentLandHolding?.projectStyles.isOther: false : false,
    projectStyleDescription: state.landHoldingsReducer.currentLandHolding ? state.landHoldingsReducer.currentLandHolding?.projectStyles? state.landHoldingsReducer.currentLandHolding?.projectStyles.isOtherDescription: '' : '',

    availableSizePercentage: state.landHoldingsReducer.currentLandHolding ? state.landHoldingsReducer.currentLandHolding?.availableSizePercentage : 0,
    
    isClayAndSilts: state.landHoldingsReducer.currentLandHolding ? state.landHoldingsReducer.currentLandHolding?.soilTypes ? state.landHoldingsReducer.currentLandHolding?.soilTypes.isClayAndSilts : false : false,
    isSand: state.landHoldingsReducer.currentLandHolding ? state.landHoldingsReducer.currentLandHolding?.soilTypes ? state.landHoldingsReducer.currentLandHolding?.soilTypes.isSand : false : false,
    isGravel: state.landHoldingsReducer.currentLandHolding ? state.landHoldingsReducer.currentLandHolding?.soilTypes ? state.landHoldingsReducer.currentLandHolding?.soilTypes.isGravel : false : false,
    isLoam: state.landHoldingsReducer.currentLandHolding ? state.landHoldingsReducer.currentLandHolding?.soilTypes ? state.landHoldingsReducer.currentLandHolding?.soilTypes.isLoam : false : false,
    isRocky: state.landHoldingsReducer.currentLandHolding ? state.landHoldingsReducer.currentLandHolding?.soilTypes ? state.landHoldingsReducer.currentLandHolding?.soilTypes.isRocky : false : false,
    isOutCroppingRock: state.landHoldingsReducer.currentLandHolding ? state.landHoldingsReducer.currentLandHolding?.soilTypes ? state.landHoldingsReducer.currentLandHolding?.soilTypes.isOutCroppingRock : false : false,
    soilAndDescriptionOther: state.landHoldingsReducer.currentLandHolding ? state.landHoldingsReducer.currentLandHolding?.soilTypes ? state.landHoldingsReducer.currentLandHolding?.soilTypes.isOther : false : false,
    soilTypeDescription: state.landHoldingsReducer.currentLandHolding ? state.landHoldingsReducer.currentLandHolding?.soilTypes ? state.landHoldingsReducer.currentLandHolding?.soilTypes.isOtherDescription : '' : '',
    
    furtherInformationOrComments: state.landHoldingsReducer.currentLandHolding ? state.landHoldingsReducer.currentLandHolding?.furtherInformationOrComments : '',

    mapGraphItems: state.landHoldingsReducer.currentLandHolding ? state.landHoldingsReducer.currentLandHolding?.mapGraphItems : [],
    createdBy: state.landHoldingsReducer.currentLandHolding ? state.landHoldingsReducer.currentLandHolding?.createdBy : '',
    updatedBy: state.landHoldingsReducer.currentLandHolding ? state.landHoldingsReducer.currentLandHolding?.updatedBy : ''//,
    //createdAt: new Date(),
    //updatedAt: new Date()
  },
  keepDirtyOnReinitialize: true,
  enableReinitialize: true,
  updateUnregisteredFields: true
});

//ReduxForm decorator populates the initialValues prop from Connect into "form" tag components:
export default connect<any, any, any>(mapStateToProps, {
  CreateLandholding,
  UpdateLandholding,
  getReducerObj,
  UpdateForm,
  GetCorrelationId,
  clearCurrentLandholding
})(reduxForm({
  //validate,
  form: 'LandholdingForm'
})(AddLandholding)) 