import React from 'react';

import 'bootstrap/dist/css/bootstrap.min.css'
import {Button, Jumbotron} from 'react-bootstrap';

interface WelcomeProps {
  isAuthenticated: boolean;
  authButtonMethod: any;
  user: any;
}

interface WelcomeState {
  isOpen: boolean;
}

function WelcomeContent(props: WelcomeProps) {
  // If authenticated, greet the user
  if (props.isAuthenticated) {
    return (
      <div>
        <h4>Hi {props.user.name}!</h4>
        <p>Use the navigation bar at the top to view your user profile.</p>
      </div>
    );
  }

  // Not authenticated, present a sign in button
  return (
    <Button color="primary" onClick={props.authButtonMethod}>Click here to sign in</Button>
  );
}

class Home extends React.Component<WelcomeProps, WelcomeState> {
  render() {
    return (
      <Jumbotron>
        <h4>Welcome to LandPortal</h4>
        <p className="lead">
          One place to serve all your needs related to Greening Australia!
        </p>
        <WelcomeContent
          isAuthenticated={this.props.isAuthenticated}
          user={this.props.user}
          authButtonMethod={this.props.authButtonMethod} />
      </Jumbotron>
    );
  }
}

export default Home;
