import React, {Component} from 'react';
import { ToastContainer } from 'react-toastify';
import TagManager from 'react-gtm-module';

import { getUserProfile } from '../../Store/ActionCreators';
import { connect } from 'react-redux';
import { selectUserProfile } from '../../Store/Selectors';

//import withAuthProvider, { AuthComponentProps } from '../../Providers/AuthProvider';
import IAuthProps from '../../Containers/Auth/IAuthProps';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';
import './App.scss';

import Menubar from '../Layout/Menubar/Menubar';
import Footer from '../Layout/Footer';
import Home from '../Pages/Home';

import Landportal from '../Pages/Landportal';
import ThankYou from '../LandPortal/ThankYou';

import About from '../Pages/About';
import Contact from '../Pages/Contact';
import News from '../Pages/News';
import Logout from '../Pages/Logout';
import Version from '../Pages/Version';
import NotFound from '../Pages/NotFound';
import GetSignalRConnection from '../../SignalR/setupFunctions';

import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';

import Project from '../LandPortal/Project';
import Users from '../Users/Users';
import TestRBS from '../Pages/TestRBS';
import UserProfile from '../Pages/UserProfile/UserProfile';
import GetDate from '../LandPortal/GetDate';
import Privacy from '../Pages/Privacy';
import TermsAndConditions from '../Pages/TermsAndConditions';
import FirstLogin from '../Pages/FirstLogin';
import AddLandholding from '../LandPortal/AddLandholding';
import Chat from '../Pages/Chat/Chat';
import { getSignalRNegotiationInfo } from '../../Store/Reducers/SignalRSlice';
import { selectSignalRAccessToken, selectSignalRUrl } from '../../Store/Selectors/signalRSelectors';
import { sendLandCareUserStatus } from '../../Store/Reducers/LandCareChatSlice';
import { getIsUserAuthenticated } from '../../Services/serviceHelper';
import { msalInstance } from '../../Containers/Auth/MsalBrowserProvider';
import { msalConfig } from '../../Containers/Auth/authConfig';

const tagManagerArgs = {
  gtmId: 'GTM-P6XZX5W'
}

TagManager.initialize(tagManagerArgs);

interface IAppState {
  isOpen: boolean;
}

interface IAuthState {
  isAuthenticated: boolean;
}

class App extends Component<IAuthProps, IAuthState> {
  pingUserStatusTimeoutInterval: any;

  constructor(props: any) {
    super(props);

    this.state = { isAuthenticated: false };

    const isAuthenticated = getIsUserAuthenticated();

    setTimeout(() => {
      if (!isAuthenticated) {
        //this.context.router.push(process.env.REACT_APP_GERX_LOGOUT_REDIRECT_URL)
        const pos = window.location.href.search('signout');
        if (pos === -1) {
          this.props.login();
        }
      }
    }, 2000);
  }

  componentDidMount = async () => {
    const accounts = await msalInstance.getAllAccounts();

    const acc = accounts.find((a: any) => a.idTokenClaims.aud === msalConfig.auth.clientId);

    if (acc && this.props.getAccessToken) {
      const accessToken = await this.props.getAccessToken();
      
      if (accessToken) {
        this.setState({ isAuthenticated: true });

        await this.props.getUserProfile(accessToken);

        const refreshRate: number = parseInt(process.env.REACT_APP_CHAT_USER_STATUS_PING_INTERVAL_MS || '60000');

        this.pingUserStatusTimeoutInterval =
          setInterval(
              async () => {
                  await this.props.sendLandCareUserStatus(accessToken);
              }
          , refreshRate);
      }
    }
    else {
      console.log("User is NOT Auth'd yet...");
    }
    
  }

  componentDidUpdate = async () => {
    if (this.state.isAuthenticated) {
      const accessToken = await this.props.getAccessToken();

      if (this.props.getSignalRNegotiationInfo) {
        await this.props.getSignalRNegotiationInfo(accessToken);
      }

      if (this.props?.signalRAccessToken && this.props?.signalRUrl && this.props.createSignalRConnection) {
        this.props.createSignalRConnection(this.props.signalRAccessToken, this.props?.signalRUrl);
      }
    }
  }

  signout(origin: string) {
    const originText = origin ? ('?origin=' + origin) : '';
    localStorage.clear();
    window.location.href = process.env.REACT_APP_GERX_LOGOUT_REDIRECT_NEXT_APP_URL + (origin ? '' : '/signout') || '/';
  }

  render() {
    // let error = null;
    // if (this.props.error) {
    //   error = <ErrorMessage
    //     message={this.props.error.message}
    //     debug={this.props.error.debug} />;
    // }

    return (
      <>
      <Router>
        <Route path="/signout" exact render={(props) => {
          const origin = props.location.search.split('?origin=')[1] || '';
          localStorage.clear();
          setTimeout(() =>{
            this.signout(origin);
          }, 500);
          
          return <>Please wait while we sign you out from GERX</>
        }} />
      </Router>
      {
        window.location.href.search("/signout") > -1 ? <></> :
        <Router>
        <ToastContainer />
        {/* <Header isAuthenticated={this.state.isAuthenticated}/> */}
        <div className="App">
          <div id="wrapper">
            <Menubar 
            isAuthenticated={this.state.isAuthenticated}
            authButtonMethod={() => this.signout('')}
            signUpButtonMethod={this.props.signup}
            user={this.props.user} />
              
            <Switch>
              <Route path="/" exact render={(props: any) => (
                  <Landportal {...props}
                  isAuthenticated={this.state.isAuthenticated}
                  user={this.props.user}
                  authButtonMethod={this.props.login} 
                  getAccessToken={this.props.getAccessToken}
                  getTokens={this.props.getTokens}
                  />
                )
              } />

              <Route path="/signout" exact render={(props) => {
                return <div className="my-3 text-center">Please wait while signing out from Landportal application</div>
              }} />

              <Route path="/home" render={(props) => (
                  <Home {...props}
                  isAuthenticated={this.state.isAuthenticated}
                  user={this.props.user}
                  authButtonMethod={this.props.login} />
                )
              } />
              
              <Route path="/chat" render={(props) => (
                  <Chat {...props}
                  isAuthenticated={this.state.isAuthenticated}
                  getAccessToken={this.props.getAccessToken}
                  user={this.props.user}
                  authButtonMethod={this.props.login} />
                )
              } />

              <Route path="/about" component={About} />
              <Route path="/contact" component={Contact} />
              <Route path="/news" component={News} />
              <Route path="/version" component={Version} />
              <Route path="/logout" component={Logout} />
              <Route path="/privacy" component={Privacy} />
              <Route path="/terms" component={TermsAndConditions} />
              <Route path="/firstlogin" component={FirstLogin} />

              <Route path="/landportal/thankyou" exact render={(props: any) => (
                <ThankYou />
              )}
              />

              {/* <Route path="/landportal/addlandholding" exact component={AddLandholding} /> */}
              <Route path="/landportal/addproperty" exact render={(props) => (
                  <AddLandholding {...props}                  
                  isAuthenticated={this.state.isAuthenticated}
                  user={this.props.user}
                  authButtonMethod={this.props.login} 
                  getAccessToken={this.props.getAccessToken}
                  getTokens={this.props.getTokens}
                  />
                )
              } />

              <Route path="/landportal/editproperty/:id" exact render={(props) => (
                  <AddLandholding {...props}                  
                  isAuthenticated={this.state.isAuthenticated}
                  user={this.props.user}
                  authButtonMethod={this.props.login} 
                  getAccessToken={this.props.getAccessToken}
                  getTokens={this.props.getTokens}
                  />
                )
              } />

              <Route path="/userprofile" render={(props) => (
                  <UserProfile {...props}
                    isAuthenticated={this.state.isAuthenticated}
                    user={this.props.user}
                    userProfile={this.props.userProfile}
                    authButtonMethod={this.props.login} 
                    getAccessToken={this.props.getAccessToken} 
                    />
                )
              } />

              <Route path="/callapi" render={(props) => (
                  <GetDate {...props}
                  isAuthenticated={this.state.isAuthenticated}
                  user={this.props.user}
                  authButtonMethod={this.props.login} 
                  getAccessToken={this.props.getAccessToken}
                  getTokens={this.props.getTokens}/>
                )
              } />

              <Route path="/test" component={TestRBS} />
              <Route path="/projects/:projectId" component={Project} />
              <Route path="/users/:userId" component={Users} />
              <Route default component={NotFound} />

            </Switch>
          </div>
        </div>
        <Footer/>
      </Router>
      }
      </>
    );
  }
}

//export default App;
//export default withAuthProvider(App);

const mapStateToProps = (state: any) => {
  return {
    userProfile: selectUserProfile(state),
    signalRAccessToken: selectSignalRAccessToken(state),
    signalRUrl: selectSignalRUrl(state)
  }
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    getUserProfile: (payload: string) => dispatch(getUserProfile(payload)),
    getSignalRNegotiationInfo: (payload: string) => dispatch(getSignalRNegotiationInfo(payload)),
    createSignalRConnection: (signalRAccessToken: string, signalRUrl: string) => GetSignalRConnection.createConnection(signalRAccessToken, signalRUrl),
    getSignalRConnection: () => GetSignalRConnection.getConnection(),
    sendLandCareUserStatus: (accessToken: string) => dispatch(sendLandCareUserStatus(accessToken))
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
