import axios, { AxiosRequestConfig } from 'axios';
import { MessageModeEnum, showMessage } from '../Helpers/Validator/validationHelper';
import { IUserProfile } from '../Models/Auth/IUser';
import { IChatMessage, IChatSummary } from '../Models/LandCare/IChat';
import { ILandCareFilePatchResponse } from '../Models/LandCare/LandCare';
import { ILandCareUserOrgContact } from '../Models/LandCare/LandCare';
import { IMediaFileResponse } from '../Models/MediaFiles/IMediaFile';
import { getConfig, getUrlParamWithValue } from './serviceHelper';

class ChatApiService {

  baseUrl: string | undefined;
  baseUrlHoldings: string | undefined;
  baseModuleName: string | undefined;
  containerName: string | undefined;

  constructor () {
    // this.baseUrl = 'https://gerxdev.azure-api.net/landcarers/';
    // this.baseUrlHoldings = 'https://gerxdev.azure-api.net/landportal/'
    this.baseUrl = process.env.REACT_APP_LANDPORTAL_HOLDINGS_LANDCARE_API_URL;
    this.baseUrlHoldings = process.env.REACT_APP_LANDPORTAL_HOLDINGS_BASEURI;
    this.baseModuleName = process.env.REACT_APP_BASE_MODULE_NAME;
    this.containerName = process.env.REACT_APP_LANDPORTAL_CONTAINER_NAME;
  }

 
  showComposedMessage = (methodName: string, url: string, error: any) => {
    const message = `[${methodName}]: ${error.message} - ${url}`;
    showMessage(message, MessageModeEnum.ERROR);
    console.log(message);
  }
  
  uploadMediaFilesById = async (accessToken: string, module: string, moduleRefId: string, files: Array<any>) : Promise<any> => {
    const containerName = process.env.REACT_APP_LANDCARE_CONTAINER_NAME; 
    let responseData = {};
    let formData = new FormData();

    for(var i = 0; i< files.length; i++)
    {
      formData.append(files[i].type, files[i], files[i].name);
    }    
    
    const config : AxiosRequestConfig = {
      headers: { 
        'Authorization': `Bearer ${accessToken}`,
        'Content-Type': 'multipart/form-data'
      }
    };

    const fileUploadUrl = `${this.baseUrl}media?container=${containerName}&module=${module}&moduleRefId=${moduleRefId}`;
    
    try {
      const uploadResponse = await axios.post(fileUploadUrl, formData, config);
      responseData = uploadResponse.data;
      return responseData;
    }
    catch (ex: any) {
      this.showComposedMessage('uploadMediaFilesById', JSON.stringify(formData), ex);
    }
  }
  
  getMediaFilesById = async (accessToken: string, module: string, moduleRefId: string, fileId?: string) : Promise<any> => {

    const containerName = process.env.REACT_APP_LANDCARE_CONTAINER_NAME; 
    const config : AxiosRequestConfig = {
      headers: { 
        'Authorization': `Bearer ${accessToken}`
      },
    };

    let landMediaUrl = `${this.baseUrl}media?container=${containerName}&module=${module}&moduleRefId=${moduleRefId}&id=${fileId}`;

    try {
      return await axios.get(landMediaUrl, config);
    }
    catch (ex: any) {
      this.showComposedMessage('getMediaFilesById', landMediaUrl, ex);
    }
  }

  patchMediaFilesById = async (accessToken: string, module: string, moduleRefId: string, fileId: string, fieldName: string, fieldValue: string) : Promise<ILandCareFilePatchResponse> => {
    const params = {
      id: fileId,
      [fieldName]: fieldValue
    };

    const containerName = process.env.REACT_APP_LANDCARE_CONTAINER_NAME; 
    const putMethod = {
      method: 'PATCH',
      headers: { 
        'Authorization': `Bearer ${accessToken}`,
        'Content-type': 'application/json; charset=UTF-8' 
      },
      body: JSON.stringify(params)
    };

    const baseUrl = process.env.REACT_APP_LANDCARE_API_BASEURI; 
    let landMediaUrl = `${this.baseUrl}media?container=${containerName}&module=${module}&moduleRefId=${moduleRefId}&id=${fileId}`;

    let response: any;

    try {
      response = await fetch(landMediaUrl, putMethod);
    }
    catch (ex: any) {
      this.showComposedMessage('patchMediaFilesById', JSON.stringify(params), ex);
    }
    finally {}
      return response.json().then(((r: any) => r.result));
  };

  downloadMediaFileById = (accessToken: string, module: string, moduleRefId: string, fileId: string) : Promise<Blob>=> {

    const containerName = process.env.REACT_APP_LANDCARE_CONTAINER_NAME; 
    const config : AxiosRequestConfig = {
      headers: { 
        'Authorization': `Bearer ${accessToken}`
      },
      responseType: 'blob',
      timeout: 30000
    };
  
    const attachmentMediaUrl = `${this.baseUrl}media?container=${containerName}&module=${module}&moduleRefId=${moduleRefId}&id=${fileId}&asFile=true`;
    
    let response: any;
    try {
      response = axios.get(attachmentMediaUrl, config);
    }
    catch (ex: any) {
      this.showComposedMessage('downloadMediaFileById', JSON.stringify(attachmentMediaUrl), ex);
    }
    finally {
      return response;
    }
  }

  deleteMediaFileById = async (accessToken: string, module: string, moduleRefId: string, fileId: string) : Promise<any>=> {

    const containerName = process.env.REACT_APP_LANDCARE_CONTAINER_NAME; 
    let responseData = {};

    const config : AxiosRequestConfig = {
      headers: { 
        'Authorization': `Bearer ${accessToken}`
      }
    };
  
    const deleteMediaUrl = `${this.baseUrl}media?container=${containerName}&module=${module}&moduleRefId=${moduleRefId}&id=${fileId}`;

    try {
      const response = await axios.delete(deleteMediaUrl, config);
      responseData = response.data;
      return responseData;
    }
    catch (ex: any) {
      this.showComposedMessage('downloadMediaFileById', deleteMediaUrl, ex);
    }
  }

  fetchLandCareChatSummaries = async (accessToken: string, pageSize: number) : Promise<IChatSummary[]> => {
    const url = `${this.baseUrl}chatsummary`
                  + getUrlParamWithValue('forlandportal', true, true)
                  + getUrlParamWithValue('pageSize', pageSize);

    let response: any;
    try {
      response = await axios.get(url, getConfig(accessToken));
    }
    catch (ex: any) {
      this.showComposedMessage('fetchLandCareChatSummaries', url, ex);
    }
    finally {
      return response.data.chatSummary;
    }
  };

  fetchLandCareChatMessages = async (accessToken: string, groupid: string, pageNumber: number, pageSize: number) : Promise<IChatMessage[]> => {
    const url = `${this.baseUrl}chats`
                  + getUrlParamWithValue('groupid', groupid, true)
                  + getUrlParamWithValue('pagenumber', pageNumber);
                  // + getUrlParamWithValue('pagesize', pageSize);

    let response: any;
    try {
      response = await axios.get(url, getConfig(accessToken));
    }
    catch (ex: any) {
      this.showComposedMessage('fetchLandCareChatMessages', url, ex);
    }
    finally {
      return response?.data.chatMessages;
    }
  };

  postLandCareChatMessage = async (accessToken: string, fromContactId: string, toContactId: string, groupId: string, message: string, files: string[]) : Promise<IChatMessage> => {
    const url = `${this.baseUrl}chats`;
    const payload = {
      userOrgContactId: toContactId,
      groupId,
      body: message,
      files
    };

    let response: any;
    try {
      response = await axios.post(url, payload, getConfig(accessToken));
    }
    catch (ex: any) {
      this.showComposedMessage('postLandCareChatMessage', JSON.stringify(payload), ex);
    }
    finally {
      return response?.data.result;
    }
  };

  postLandCareUserStatus = async (accessToken: string) : Promise<IChatMessage> => {
    const url = `${this.baseUrl}chats/ping`;

    let response: any;
    try {
      response = await axios.post(url, null, getConfig(accessToken));
    }
    catch (ex: any) {
      //this.showComposedMessage('postLandCareUserStatus', url, ex);
      console.log('[postLandCareUserStatus]', url);
    }
    finally {
      return response?.data.result;
    }
  };

  postLandCareChatMessageReadNotification = async (accessToken: string, groupId: string) : Promise<IChatMessage> => {
    const url = `${this.baseUrl}chats/messagesread`;
    const payload = {
      groupId
    };

    let response: any;
    try {
      response = await axios.post(url, payload, getConfig(accessToken));
    }
    catch (ex: any) {
      this.showComposedMessage('postLandCareChatMessageReadNotification', JSON.stringify(payload), ex);
    }
    finally {
      return response?.data.result;
    }
  };
};

export default ChatApiService;