import React from 'react';
import {Link} from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';

interface Props {}

interface State {
  isOpen: boolean
}

class Footer extends React.Component<Props, State> {

  constructor(props: Props, state: State) {
    super(props);
    this.state = {
      isOpen: false
    };
  }

  render() {
    return (
    <footer className="App-Footer">
        <div style={{ paddingTop: '20px'}}>
          <span><Link to={`/privacy`}>Privacy</Link> | &copy; Copyright <a href='https://www.greeningaustralia.org.au/'>Greening Australia</a> 2020 | <Link to={'/terms'}>Terms &amp; Conditions</Link></span>
        </div>
    </footer>
    );
  }
}

export default Footer;
