import React from 'react';
import {Link} from 'react-router-dom';
import {Button, Navbar, Nav, NavItem} from 'react-bootstrap'
import MenuBanner from '../../../Assets/Images/bannerlogo.png';

import 'bootstrap/dist/css/bootstrap.min.css';
import './Menubar.scss';

interface INavbarProps {
  isAuthenticated: boolean;
  authButtonMethod: any;
  signUpButtonMethod: any;
  user: any;
}

interface INavbarState {
  isOpen: boolean;
}


function AuthNavItem(props: INavbarProps) {

  const homeUrl = process.env.REACT_APP_GERX_LOGOUT_REDIRECT_URL || '/';

  return (
    <> 
      <a className="nav-link" href={homeUrl}>Home</a>
      <Nav.Link as={Link} to={"/about"}>About</Nav.Link>
      {/* <Nav.Link as={Link} to={"/chat"}>Chat</Nav.Link> */}
      <Nav.Link as={Link} to={"/"}>Properties</Nav.Link>
      <Nav.Link as={Link} to={"/userprofile"}>My Profile</Nav.Link>
      <NavItem>
        <Button
          onClick={props.authButtonMethod}
          className="btn-link nav-link border-0"
          color="link">Sign Out</Button>
      </NavItem>
    </>
  );
}


class Menubar extends React.Component<INavbarProps, INavbarState> {

  wrapper: React.RefObject<unknown>;

  constructor(props: INavbarProps, state: INavbarState) {
    super(props);

    //KS: To resolve console warnings
    this.wrapper = React.createRef();

    //KS: Set default state params as required
    this.state = {
      isOpen: false
    };
  }

  render() {

    return (
        <div className="gerx-menubar">
          <Navbar.Brand as={Link} to={"/home"}><img src={MenuBanner} className="logo-nav" alt="logo" /></Navbar.Brand>
          <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
              <Nav className="mr-auto">
              </Nav>
              <Nav>
              <AuthNavItem  isAuthenticated={this.props.isAuthenticated}
                            authButtonMethod={this.props.authButtonMethod}
                            signUpButtonMethod={this.props.signUpButtonMethod}
                            user={this.props.user}
              />
              </Nav>
            </Navbar.Collapse>
          </Navbar>
        </div>
    );
  }
}

export default Menubar;
