import { createSelector } from 'reselect';
import { IUserProfile } from '../../Models/Users/User';
import { RootState } from '../RootStore';

//import { ILandCarer, ILandCareUserOrg, ILandCareUserOrgContact } from '../../Models/LandCare/LandCare';

export const landHoldingSelector = (state: RootState) => state.landHoldingsReducer;

// export const selectUserOrg = createSelector(
//     [landCareSelector],
//     (state: any) => state.userOrg as ILandCareUserOrg
// );

export const selectUserProfile = createSelector(
    [landHoldingSelector],
    (state: any) => state?.userProfile as IUserProfile
);
