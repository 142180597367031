import { createSlice, PayloadAction} from '@reduxjs/toolkit';
import { IUserProfile, IUserRole } from '../../Models/Auth/IUser';
// import { fetchUserProfile } from '../Api/LandCareApi';
import { AppThunk } from '../RootStore';

export interface IEditingRow {
  rowNo: number;
  isEditing: boolean;
};

export interface IFocusInfo {
  fieldName: string;
  rowNo: number;
};

export enum AuthStatusEnum {
  Undefined,
  Authenticated,
  NotAuthenticated
};

export interface LocalStoreState {
  currentLandHoldingStep: number;
  isSideMenuExpanded: boolean;
  currentMenuOption: string;
  accessToken: string;
  authStatus: AuthStatusEnum;
  isEditingRow: IEditingRow[];
  focusInfo: IFocusInfo;
  userProfile: IUserProfile;
  error: any;
  isLoading: boolean;
  modulePageInfo: {
    home: IModulePageInfo
  };
};

export interface IModulePageInfo {
  pageNumber?: number,
  pageSize?: number,
  recordCount?: number
};

export interface IModulePageInfoPayload {
  moduleName: ModulesEnum,
  modulePageInfo: IModulePageInfo
}

const initialState: LocalStoreState = {
  currentLandHoldingStep: 1,
  isSideMenuExpanded: false,
  currentMenuOption: '',
  accessToken: '',
  authStatus: AuthStatusEnum.Undefined,
  isEditingRow: [],
  focusInfo: {} as IFocusInfo,
  userProfile: {} as IUserProfile,
  error: {},
  isLoading: false,
  modulePageInfo: {
    home: {
      pageNumber: 1,
      pageSize: 10,
      recordCount: 0
    }
  }
};

export enum ModulesEnum {
  Home
};

const LocalStoreSlice = createSlice({
  name: "localStore",
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true
    },
    stopLoading(state) {
      state.isLoading = false
    },
    setLandHoldingStep: (state: LocalStoreState, {payload}: PayloadAction<number>) => { 
      //KS: Can have array.push, etc in here too! ;)
      state.currentLandHoldingStep = payload as number
    },
    toggleSideMenu(state: LocalStoreState) {
      state.isSideMenuExpanded = !state.isSideMenuExpanded;
    },
    setSideMenuExpanded(state: LocalStoreState, {payload}: PayloadAction<boolean>) {
      state.isSideMenuExpanded = payload;
    },
    setCurrentMenuOption(state: LocalStoreState, {payload}: PayloadAction<string>) {
      state.currentMenuOption = payload
    },
    setAccessToken(state: LocalStoreState, {payload}: PayloadAction<string>) {
      state.accessToken = payload
    },
    setAuthStatus(state: LocalStoreState, {payload}: PayloadAction<AuthStatusEnum>) {
      state.authStatus = payload
    },
    setRowEditing(state: LocalStoreState, {payload}: PayloadAction<IEditingRow>) {
      let editingArray = [...state.isEditingRow];
      editingArray[payload.rowNo] = payload;
      state.isEditingRow = editingArray;
    },
    clearRowEditing(state: LocalStoreState) {
      state.isEditingRow = [];
    },
    setFocusInfo(state: LocalStoreState, {payload}: PayloadAction<IFocusInfo>) {
      state.focusInfo = payload;
    },
    clearFocusInfo(state: LocalStoreState) {
      state.focusInfo = {} as IFocusInfo;
    },
    getUserProfileSuccess(state: LocalStoreState, {payload}: PayloadAction<IUserProfile>) {
      state.userProfile = payload;
    },
    getUserProfileFailed(state: LocalStoreState, {payload}: PayloadAction<any>) {
      state.error = payload;
    },
    setModulePageInfo(state: LocalStoreState, {payload}: PayloadAction<IModulePageInfoPayload>) {
      switch (payload.moduleName) {
        case ModulesEnum.Home: state.modulePageInfo.home = {
          ...state.modulePageInfo.home,
          ...payload.modulePageInfo
        };
        break;
      }
    }
  }
});

//KS: Export reducer (Can include other reducers here too)
export const LocalStoreReducers = LocalStoreSlice.reducer;

// export const getUserProfile = (accessToken: string, history: any) : AppThunk => async dispatch => {
//   try {
//     const result = await fetchUserProfile(accessToken);
//     const index = result?.roles?.findIndex((role: IUserRole) => role.roleName === 'GERXUser');
//     if (index === -1) {
//       //history.push('/unauthorized');
//     }
//     await dispatch(getUserProfileSuccess(result));
//   } catch(err) {
//     dispatch(getUserProfileFailed(err));
//   }
// };

// //KS: Export action creators
export const { startLoading, stopLoading, setLandHoldingStep, toggleSideMenu, setSideMenuExpanded, setCurrentMenuOption, setAccessToken, setRowEditing, clearRowEditing, setFocusInfo, clearFocusInfo, getUserProfileSuccess, getUserProfileFailed, setModulePageInfo, setAuthStatus } = LocalStoreSlice.actions;
