import React from 'react';

import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Jumbotron } from 'react-bootstrap';

interface Props { }

interface State {
}

class FirstLogin extends React.Component<Props, State> {

  constructor(props: Props, state: State) {
    super(props);
  }

  render() {
    return (
      <>
        <Jumbotron fluid >
          <Container fluid style={{marginLeft:'100px'}}>
            <h2 className="display-4" >Thanks for registering!</h2>
            <p className="lead">
            Thank you for registering with GERX for Land Restoration with Greening Australia 
            </p> 
            <p>Describe your property and restoration goals to receive tailored proposals. </p>
            <p>Click on "Properties" on the menu bar on the top to register your property details </p>
            <pre>Video GIF</pre>
          </Container>
        </Jumbotron>
      </>
    );
  }
}

export default FirstLogin;
