import {
  ActionTypes 
} from '../Actions/types';
import _ from 'lodash';
import { ILandholding, ILandholdingAddress } from '../../Models/Landholdings/Landholding';
import { toast, ToastOptions } from 'react-toastify';

interface ILandHolding {
  storeLandHoldings: any[];
  currentLandHolding: ILandholding;
  isFetching: boolean;
  userProfile: any;
  bannerData: any;
  error: string;
}

export const initialState : ILandHolding = {
  storeLandHoldings: [],
  currentLandHolding: {} as ILandholding,
  isFetching: true,
  userProfile: {},
  bannerData: {},
  error: ''
}

export const landHoldingsReducer = (state: ILandHolding = initialState, action: { type: ActionTypes; payload: any /*{ _id: string; index: number; name: string}*/ }) => {
  switch (action.type) {
    case ActionTypes.getLandholdings:
      return {
        ...state,
        storeLandHoldings: _.concat(action.payload),
        isFetching: false,
        error: ''
      }
    case ActionTypes.getNewReducerObj:
      return {
        ...state
      }
    case ActionTypes.updateForm:
      console.log('Obj updated to reducer: ', action.payload)
      return {
        ...state,
        currentLandHolding: action.payload,
        error: ''
      }
    case ActionTypes.stopFetching:
      return {
        ...state,
        isFetching: false,
        error: ''
      }
    case ActionTypes.createLandholding:
      console.log('Saved in reducer: ', action.payload)
      return {
        ...state,
        currentLandHolding: action.payload,
        storeLandHoldings: _.concat(action.payload),
        error: ''
      }
    case ActionTypes.getLandholdingById:
      return {
        ...state,
        currentLandHolding: action.payload,
        error: ''
      }
    case ActionTypes.deleteLandholdings:
      return {
        ...state,
        storeLandHoldings: _.filter((storeLandHoldings: ILandholding) => storeLandHoldings._id !== action.payload._id),
        error: ''
      }
    case ActionTypes.getUserProfile:
      return {
        ...state,
        userProfile: action.payload,
        error: ''
      }
    case ActionTypes.saveUserProfile:
      return {
        ...state,
        userProfile: action.payload,
        error: ''
      }
    case ActionTypes.copyAddress: {
      let currentLandHolding = state.currentLandHolding;
      if (action.payload) {
        currentLandHolding = {
          ...currentLandHolding,
          postalAddress: currentLandHolding.address
        };
      }
      else {
        currentLandHolding = {
          ...currentLandHolding,
          postalAddress: { line1: '', state: '', postCode: '' } as ILandholdingAddress
        };
      }
      return {
        ...state,
        currentLandHolding,
        error: ''
      }
    }
    case ActionTypes.setCorrelationId: {
      const currentLandHolding = {
        ...state.currentLandHolding,
        correlationId: action.payload
      }
      return {
        ...state,
        currentLandHolding
      };
    }
    case ActionTypes.clearCurrentLandholding: {
      return {
        ...state,
        currentLandHolding: {} as ILandholding
      };
    }
    case ActionTypes.setError: {
      const msgObj: ToastOptions = {
        position: "top-right",
        autoClose: 4000,
        hideProgressBar: false,
        closeButton: true,
        closeOnClick: false,
        draggable: false,
        progress: undefined
      };

      toast.error(action.payload, msgObj);
      
      return {
        ...state,
        error: action.payload
      }
    }
    case ActionTypes.resetError: {
      return {
        ...state,
        error: ''
      }
    }
    default:
      return state;
  }
}

export default landHoldingsReducer;