import { ILandCareFilePatchResponse } from '../../Models/LandCare/LandCare';
import { IChatMessage, IChatSummary } from '../../Models/LandCare/IChat';
import { IUserProfile } from '../../Models/Auth/IUser';
import { IMediaFileResponse } from '../../Models/MediaFiles/IMediaFile';
import ChatApiService from '../../Services/ChatApiService';

const landCareApiService = new ChatApiService();

export const uploadMediaFilesById = async (accessToken: string, module: string, moduleRefId: string, files: Array<any>) : Promise<IMediaFileResponse> => {
  return await landCareApiService.uploadMediaFilesById(accessToken, module, moduleRefId, files);
};

export const getMediaFilesById = async (accessToken: string, module: string, moduleRefId: string, fileId: string) : Promise<any> => {
  return await landCareApiService.getMediaFilesById(accessToken, module, moduleRefId, fileId);
};

export const patchMediaFilesById = async (accessToken: string, module: string, moduleRefId: string, fileId: string, fieldName: string, fieldValue: string) : Promise<ILandCareFilePatchResponse> => {
  return await landCareApiService.patchMediaFilesById(accessToken, module, moduleRefId, fileId, fieldName, fieldValue);
};

export const downloadMediaFilesById = async (accessToken: string, module: string, moduleRefId: string, fileId: string) : Promise<any> => {
  return await landCareApiService.downloadMediaFileById(accessToken, module, moduleRefId, fileId);
};

export const deleteMediaFilesById = async (accessToken: string, module: string, moduleRefId: string, fileId: string) : Promise<any> => {
  return await landCareApiService.deleteMediaFileById(accessToken, module, moduleRefId, fileId);
};

export const fetchLandCareChatSummaries = async (accessToken: string, pageSize: number) : Promise<IChatSummary[]> => {  
  return await landCareApiService.fetchLandCareChatSummaries(accessToken, pageSize);
};

export const fetchLandCareChatMessages = async (accessToken: string, groupId: string, pageNumber: number, pageSize: number) : Promise<IChatMessage[]> => {  
  return await landCareApiService.fetchLandCareChatMessages(accessToken, groupId, pageNumber, pageSize);
};

export const postLandCareChatMessage = async (accessToken: string, fromContactId: string, toContactId: string, groupId: string, message: string, files: string[]) : Promise<any> => {  
  return await landCareApiService.postLandCareChatMessage(accessToken, fromContactId, toContactId, groupId, message, files);
};

export const postLandCareUserStatus =  async (accessToken: string) : Promise<any> => {
  return await landCareApiService.postLandCareUserStatus(accessToken);
};

export const postLandCareChatMessageReadNotification = async (accessToken: string, groupId: string) : Promise<any> => {
  return await landCareApiService.postLandCareChatMessageReadNotification(accessToken, groupId);
};