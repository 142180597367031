import axios, { AxiosRequestConfig } from 'axios';

class LandholdingsApiService {

  baseUrl: string | undefined;
  landCareAPIUrl: string | undefined;
  containerName: string | undefined;
  //landholdings : Array<ILandholding>;
  mainUrl: string | undefined;

  constructor () {
    //Setup defaults if any...
    this.baseUrl = process.env.REACT_APP_LANDPORTAL_HOLDINGS_BASEURI;
    this.landCareAPIUrl = process.env.REACT_APP_LANDPORTAL_HOLDINGS_LANDCARE_API_URL;
    this.containerName = process.env.REACT_APP_LANDPORTAL_CONTAINER_NAME;
    this.mainUrl = process.env.REACT_APP_API_URI;
    //this.landholdings = this.getDefaultLandholdings();
  }

  getLandholdingById = async (accessToken: string, landholdingId: string) : Promise<any> => {

    let responseData = {};

    const config : AxiosRequestConfig = {
      headers: { 
        'Authorization': `Bearer ${accessToken}`
      }
    };
  
    const landholdingsUrl = `${this.baseUrl}holdings?id=${landholdingId}`;
    const response = await axios.get(landholdingsUrl, config);

    responseData = response.data.landholdings;
    return responseData;
  }


  getMediaFilesById = async (accessToken: string, containerId: string, module: string, moduleRefId: string,  id?: string, asFile?: boolean ) : Promise<any> => {

    let responseData = {};

    const config : AxiosRequestConfig = {
      headers: { 
        'Authorization': `Bearer ${accessToken}`
      }
    };

    // console.log('container ID in service: ', containerId)
    // console.log('folderID in service: ', moduleRefId)
    // console.log('ID for getMedia: ', id)
  
    let landMediaUrl = `${this.landCareAPIUrl}media?container=${containerId}&module=${module}&moduleRefId=${moduleRefId}`;
    if(id && id.length > 0) {
      if(asFile) {
        landMediaUrl = `${landMediaUrl}&id=${id}&asFile=true`;
      }
      else {
        landMediaUrl = `${landMediaUrl}&id=${id}`;
      }
    } /*else {
      const mediafiles: any = []
      return mediafiles
    }*/
    
    const response = await axios.get(landMediaUrl, config);

    responseData = response;
    return responseData;
  }

  downloadMediaFileById = (accessToken: string, containerId: string, module:string, moduleRefId: string, id: string) => {

    const config : AxiosRequestConfig = {
      headers: { 
        'Authorization': `Bearer ${accessToken}`
      },
      responseType: 'blob',
      timeout: 30000
    };
  
    const landMediaUrl = `${this.landCareAPIUrl}media?container=${containerId}&module=${module}&moduleRefId=${moduleRefId}&id=${id}&asFile=true`;
   
    return axios.get(landMediaUrl, config);
  }


  uploadMediaFilesById = async (accessToken: string, containerId: string, module: string, moduleRefId: string, files: Array<any>) : Promise<any> => {
    
    let responseData = {};
    let formData = new FormData();

    //This file is here from Browse
    console.log('Files in update: ', files);

    for(var i = 0; i< files.length; i++)
    {
      formData.append(files[i].type, files[i], files[i].name);
    }    
    
    const config : AxiosRequestConfig = {
      headers: { 
        'Authorization': `Bearer ${accessToken}`,
        'Content-Type': 'multipart/form-data'
      }
    };

    //landholderId=KaushTest&subFolder=Media
    const fileUploadUrl = `${this.landCareAPIUrl}media?container=${containerId}&module=${module}&moduleRefId=${moduleRefId}`;
    const uploadResponse = await axios.post(fileUploadUrl, formData, config);

    responseData = uploadResponse.data;

    console.log('Upload response data: ', responseData)

    return responseData;
  }


  deleteMediaFilesById = async (accessToken: string, containerId: string, moduleId: string, moduleRefId: string,  fileId: string ) : Promise<any> => {

    let responseData = {};

    const config : AxiosRequestConfig = {
      headers: { 
        'Authorization': `Bearer ${accessToken}`
      }
    };
  
    const deleteMediaUrl = `${this.baseUrl}media?container=${containerId}&module=${moduleId}&moduleRefId=${moduleRefId}&id=${fileId}`;

    const response = await axios.delete(deleteMediaUrl, config);

    responseData = response.data;
    return responseData;
  }

  // fetchCorrelationId = async (accessToken: string) : Promise<any> => {
  //   const config : AxiosRequestConfig = {
  //     headers: { 
  //       'Authorization': `Bearer ${accessToken}`
  //     }
  //   };

  //   const url = `${this.mainUrl}core/guid`;
  //   const response = await axios.get(url, config);
  //   return response.data;
  // }
}

export default LandholdingsApiService;